const fontSizes = {
    xxxxxl: '100px',
    xxxxl: '80px',
    xxxl: '70px',
    xxl: '60px',
    xl: '40px',
    l: '30px',
    m: '25px',
    s: '22px',
    xs: '20px',
    xss: '18px',
    xxs: '15px'
};

const theme = {
    default: {
        color: {
            primary: '#333',
            error: 'red',
            active: '#E94F37',
            blue: '#153295'
        },
        fontSize: {
            mobile: {
                xxxxxl: '40px',
                xxxxl: '35px',
                xxxl: '35px',
                xxl: '30px',
                xl: '22px',
                l: '20px',
                m: '18px',
                s: '16px',
                xs: '15px',
                xxs: '12px'
            }, 
            tablet: {
                xxxxxl: '80px',
                xxxxl: '60px',
                xxxl: '50px',
                xxl: '40px',
                xl: '30px',
                l: '25px',
                m: '20px',
                s: '20px',
                xs: '18px',
                xxs: '14px'
            },
            default: fontSizes
        },
        breakPoints: {
            // mobile: '500px',
            // tablet: '940px',
            mobile: '700px',
            tablet: '1100px',
        }
    }
}

export default theme.default;