import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const ImprintContainer = styled.section``;

const Main = styled.main`
  border: 3px solid #333;
  margin: 8rem;
  position: relative;
  /* height: 80rem; */
  padding: 8rem 5rem;
  h1 {
    font-size: 6rem;
    color: var(--color-5);
    background-color: var(--background-color-3);
    letter-spacing: var(--spacing-h);
    font-family: var(--abril);
    position: absolute;
    top: -3px;
    left: 15%;
    padding: 0 2rem;
    transform: translate(-50%, -50%);
  }

  h2 {
    font-size: 3rem;
    color: var(--color-2);
    font-weight: 700;
    letter-spacing: var(--spacing-p);
    font-family: var(--lato);
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 2.2rem;
    color: var(--color-2);
    font-weight: 400;
    letter-spacing: var(--spacing-p);
    font-family: var(--lato);
  }
  @media screen and (max-width: 900px) {
    padding: 4rem 2.5rem;
    margin: 3rem;
    h1 {
      font-size: 4rem;
      left: 25%;
    }
    h2 {
      font-size: 2.2rem;
    }
    p {
      font-size: 1.6rem;
    }
  }
`;

const ImprintPage = () => {
  return (
    <ImprintContainer>
      <Navbar />
      <Main>
        <h1>Imprint</h1>
        <h2>Angaben gemäß § 5 TMG</h2>
        <p>
          AcumenCog ( haftungsbeschränkt )<br />
          House A<br />
          Axel-Springer -Platz 3<br />
          20355 Hamburg Germany
          <br />
          <br />
          E-Mail: info@acumencog.io
          <br />
          Tel: +49 17626993440
          <br />
          <br />
          Sitz und Registergericht der UG ( haftungsbeschränkt )<br />
          Hamburg, Amtsgericht HRB 159306
          <br />
          UST.-ID DE 328983352
          <br />
          <br />
          Vertretungsberechtigter Gesellschafter:Praveen kumar
          <br />
          <br />
          Zu Sicherheitsthemen bezüglich unseres Services schreiben Sie uns
          bitte an folgende
          <br />
          <br />
          E-Mail-Adresse: info@acumencog.io
          <br />
          <br />
          Verbraucher­streit­beilegung/Universal­schlichtungs­stelle
          <br />
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer
          <br />
          Verbraucherschlichtungsstelle teilzunehmen.
        </p>
      </Main>
      <Footer />
    </ImprintContainer>
  );
};

export default ImprintPage;
