import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import leftButton from "../images/left-red.svg";
import quoteIcon from "../images/quote.svg";
import jsIcon from "../images/js.svg";
import mysqlIcon from "../images/mysql-green.svg";
import angularIcon from "../images/angular-green.svg";
import ionicIcon from "../images/ionic-green.svg";
import tsIcon from "../images/ts-green.svg";
import reactIcon from "../images/react-green.svg";
import javaIcon from "../images/java-green.svg";
import awsIcon from "../images/aws-green.svg";
import clientPic from "../images/client-review.png";
import ununuWork from "../images/ununu.png";
import dna4goodWebImg from "../images/dna4good-web.jpg";
import myvstatsImg from "../images/myvstats.png";
import lifepointsImg from "../images/lifepoints.png";
import bitfiImg from "../images/bitfi.png";

const WorkPageContainer = styled.div``;

const Main = styled.main`
  padding: 8rem;
  padding-top: 4rem;
  position: relative;
  @media screen and (max-width: 1300px) {
    padding: 3rem;
  }
`;

const BackButtonContainer = styled.div`
  margin-bottom: 3rem;
  @media screen and (max-width: 1300px) {
    margin-bottom: 23.8rem;
  }
  /* background-color: red; */
`;

const DescriptionContainer = styled.div`
  /* background-color: var(--background-color-5); */
  /* background: black, url(${ununuWork}); */
  /* background: linear-gradient(
      45deg,
      rgba(102, 107, 246, 0.6),
      rgba(0, 0, 0, 0.4) 40%
    ),
    url(${ununuWork}); */
  /* return (
      linear-gradient(45deg, #44bba4, rgba(0, 0, 0, 0.4) 40%),
    url(props.backImg) */
  background-image: ${(props) =>
    `linear-gradient(45deg, #44bba4, rgba(0, 0, 0, 0.4) 40%), url(${props.backImg})`};
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 80rem;
  border-radius: 1rem;
  box-shadow: 0 0 1rem rgba(102, 107, 246, 0.32);
  padding: 5rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  @media screen and (max-width: 1300px) {
    /* background: linear-gradient(
        45deg,
        rgba(102, 107, 246, 0.6),
        rgba(0, 0, 0, 0.4) 80%
      ),
      url(${ununuWork}); */
    background-image: ${(props) =>
      `linear-gradient(45deg, #44bba4, rgba(0, 0, 0, 0.4) 40%), url(${props.backImg})`};
    height: max-content;
    z-index: -2;
    border-radius: 0 0 1rem 1rem;
    position: relative;
    width: 100%;
    /* left: -3rem; */
  }
`;

const Button = styled.div`
  width: 3rem;
  display: block;
  cursor: pointer;
  img {
    width: 100%;
  }
`;
const TitleOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 20rem;
  /* background-color: var(--background-color-1); */
  background-color: rgba(0, 0, 0, 0.7);
  /* box-shadow: 0 0 1rem rgba(102, 107, 246, 0.32); */
  width: 45rem;
  height: 90rem;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: #fff;
    text-transform: uppercase;
    font-size: 5rem;
  }
  @media screen and (max-width: 1300px) {
    right: unset;
    left: 0;
    width: 100vw;
    height: 30rem;
    z-index: -1;
    /* background-color: var(--background-color-1); */
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 4rem;
  div {
    margin-bottom: 0;
  }
`;
const Title = styled.h1`
  font-size: 6rem;
  font-weight: 700;
  /* color: var(--color-2); */
  color: #333;
  margin-right: 2rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 900px) {
    font-size: 3rem;
  }
`;
const Title1 = styled(Title)`
  font-size: 4.5rem;
  @media screen and (max-width: 900px) {
    font-size: 3rem;
  }
`;

const Line = styled.div`
  margin: 0 0 30px;
  width: 18rem;
  height: 0.8rem;
  background-color: var(--background-color-4);
  @media screen and (max-width: 900px) {
    height: 0.6rem;
    width: 13rem;
    margin: 0 0 1.5rem;
  }
`;

const AbsoluteText = styled.h1`
  position: absolute;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  width: 8rem;
  left: 65%;
  top: -65%;
  transform: translate(-50%, -50%);
`;

const Desc = styled.p`
  font-size: 2.4rem;
  color: #fff;
  width: 40%;
  @media screen and (max-width: 1300px) {
    width: 100%;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.6rem;
  }
`;

const VideoContainer = styled.div`
  margin-top: 12rem;
  border-radius: 1rem;
  background-color: rgba(49, 68, 108, 0.83);
  width: 100%;
  height: 70rem;
  @media screen and (max-width: 900px) {
    margin-top: 4rem;
    height: 35rem;
  }
`;
const TechContainer = styled.div`
  margin-top: 8rem;
`;

const StackContainer = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 4rem;
`;
const Stack = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 1rem;
  min-width: 15rem;
  height: 50px;
  h4 {
    font-size: clamp(1.4rem, 2.4vw, 3rem);
  }
`;
const ImgContainer = styled.div`
  width: 5rem;
  margin-right: 2rem;
  img {
    width: 100%;
    height: 50px;
  }
`;

const TimeContainer = styled(TechContainer)``;
const TimeTaken = styled.h1`
  font-size: clamp(1.4rem, 2.4vw, 3rem);
  margin-bottom: 5rem;
`;
const PathwayContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  @media screen and (max-width: 900px) {
    flex-flow: column nowrap;
  }
`;
const First = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  @media screen and (max-width: 900px) {
    flex-flow: column nowrap;
    .absolute-text {
      top: 70%;
    }
  }
  /* width: 10rem; */
`;
const Second = styled(First)`
  /* .absolute-text {
        left: 35%;
    } */
  @media screen and (max-width: 900px) {
    top: 0;
    .absolute-text {
      left: 35%;
    }
  }
`;
const Third = styled(First)``;
const Fourth = styled(First)``;
const LineSmall = styled.div`
  height: 2px;
  width: 15rem;
  background-color: red;
  @media screen and (max-width: 900px) {
    height: 8rem;
    width: 2px;
  }
`;
const NumberContainer = styled.div`
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40rem;
  background-color: #44bba4;
  p {
    font-size: 2.2rem;
  }
`;

const ClientContainer = styled(TechContainer)``;

const CommentContainer = styled.div`
  width: 100%;
  height: 20rem;
  border-radius: 1rem;
  background: linear-gradient(
    to right,
    transparent 40%,
    rgba(68, 187, 163, 0.32) 120%
  );
  position: relative;
  padding: 5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    padding: 2rem;
    height: max-content;
    align-items: center;
  }
`;

const QuoteContainer = styled.div`
  width: 8rem;
  position: absolute;
  left: 0;
  top: 0;
  img {
    width: 100%;
  }
  @media screen and (max-width: 900px) {
    width: 5rem;
  }
`;

const ClientComment = styled.p`
  font-size: 2rem;
  letter-spacing: var(--spacing-p);
  font-weight: 700;
  color: #333;
  width: 100%;
  @media screen and (max-width: 900px) {
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    font-weight: 500;
  }
`;

const CommentSection = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    width: 90%;
  }
`;
const PictureSection = styled.div`
  @media screen and (max-width: 900px) {
    width: 8rem;
    border-radius: 30rem;
  }
  img {
    height: 100%;
    @media screen and (max-width: 900px) {
      height: unset;
      width: 100%;
      border-radius: 30rem;
    }
  }
`;

const Name = styled.h3``;
const Desig = styled.h4``;

const WorkPage = () => {
  const { workId } = useParams();
  const history = useHistory();
  console.log(workId, "work");

  const detailsArray = [
    {
      name: "LIFEPOINTS.DE",
      description:
        "Interact (like, dislike, video views) with the Lifepoints app. Collect points and exchange them for attractive rewards. This turns your social media activity into something you can touch and experience.",
      videoSrc: "",
      backImgSrc: lifepointsImg,
      tech: [
        {
          name: "React",
          src: reactIcon,
        },
        {
          name: "React Native",
          src: reactIcon,
        },
        {
          name: "MySQL",
          src: mysqlIcon,
        },
        {
          name: "Java",
          src: javaIcon,
        },
      ],
      timeTaken: "180 Days",
      review: {
        content:
          "I was amazed at the quality of AcumenCog. I am good to go! Thank You! I don't know what to say more.",
        imgSrc: clientPic,
        name: "David Dias Graxinha",
        designation: "Lifepoints",
      },
    },
    // {
    //   name: "DVIEW.IO",
    //   description:
    //     "Unified Data Platform Powered by dView's the Data Pipeline Engine. The data pipeline divides each data stream into smaller chunks that it processes in parallel, conferring extra computing power.",
    //   videoSrc: "",
    //   tech: [
    //     {
    //       name: "React",
    //       src: reactIcon,
    //     },
    //     {
    //       name: "Java",
    //       src: javaIcon,
    //     },
    //     {
    //       name: "AWS",
    //       src: awsIcon,
    //     },
    //     {
    //       name: "MySQL",
    //       src: mysqlIcon,
    //     },
    //   ],
    //   timeTaken: "30 Days",
    //   review: {
    //     content:
    //       "From the early stage of the app development to the end product, it was always a pleasure working with you.",
    //     imgSrc: clientPic,
    //     name: "John",
    //     designation: "Dview",
    //   },
    // },
    {
      name: "DNA4GOOD",
      description:
        "A web application and a progressive web app to generate covid certificates. The app supports generation of certificates for CWA, Vaccinated, Recovered cases",
      videoSrc: "",
      backImgSrc: dna4goodWebImg,
      tech: [
        {
          name: "React",
          src: reactIcon,
        },
        {
          name: "Java",
          src: javaIcon,
        },
        {
          name: "AWS",
          src: awsIcon,
        },
      ],
      timeTaken: "90 Days",
      review: {
        content:
          "From the early stage of the app development to the end product, it was always a pleasure working with you.",
        imgSrc: clientPic,
        name: "Patrick",
        designation: "Dna4Good",
      },
    },
    {
      name: "BITFI",
      description:
        "A cryptocurrency mobile application that runs on both android and ios. In this app a user can buy, sell and manage their crypto assets by using INR currency. Users can also see real time data of any crypto.",
      videoSrc: "",
      backImgSrc: bitfiImg,
      tech: [
        {
          name: "React Native",
          src: reactIcon,
        },
        {
          name: "Java",
          src: javaIcon,
        },
      ],
      timeTaken: "60 Days",
      review: {
        content:
          "From the early stage of the app development to the end product, it was always a pleasure working with you.",
        imgSrc: clientPic,
        name: "Kauts",
        designation: "Bitfi",
      },
    },
    {
      name: "UNUNU.IO",
      description:
        "Built for musicians, by musicians, ununu is a collaborative workspace that empowers you to share projects, track versions & communicate with multiple contributors all in one place.",
      videoSrc: "",
      backImgSrc: ununuWork,
      tech: [
        {
          name: "Typescript",
          src: tsIcon,
        },
        {
          name: "React",
          src: reactIcon,
        },
        {
          name: "Java",
          src: javaIcon,
        },
        {
          name: "MySQL",
          src: mysqlIcon,
        },
      ],
      timeTaken: "30 Days",
      review: {
        content:
          "The quality of work is outstanding. The final product was delivered on time as it was discussed",
        imgSrc: clientPic,
        name: "Jane",
        designation: "Ununu",
      },
    },
    {
      name: "MYVSTATS",
      description:
        "MyVStats, an app where you can keep track of your Fifa scores, tournaments, wins/loses and weekened tournament with built in OCR, compare results with your friends, add new friends. This app is available on both iOS and Android",
      videoSrc: "",
      backImgSrc: myvstatsImg,
      tech: [
        {
          name: "Typescript",
          src: tsIcon,
        },
        {
          name: "Angular",
          src: angularIcon,
        },
        {
          name: "Ionic",
          src: ionicIcon,
        },
        {
          name: "MySQL",
          src: mysqlIcon,
        },
      ],
      timeTaken: "30 Days",
      review: {
        content:
          "Flawless integration of new features and best practices by AcumenCog team, helped me in growing my app and reaching more customers",
        imgSrc: clientPic,
        name: "Martin Losch",
        designation: "MyVStats",
      },
    },
  ];

  return (
    <WorkPageContainer>
      <Navbar />
      <Main>
        <BackButtonContainer>
          <Button onClick={() => history.goBack()}>
            <img src={leftButton} alt="Back Button Icon" />
          </Button>
        </BackButtonContainer>
        <DescriptionContainer backImg={detailsArray[workId].backImgSrc}>
          <TitleContainer>
            <Title style={{ color: "#fff" }}>
              Project
              <br />
              Description
            </Title>
            <Line />
          </TitleContainer>
          <Desc>{detailsArray[workId].description}</Desc>
        </DescriptionContainer>
        <TitleOverlay>
          <h1>{detailsArray[workId].name}</h1>
        </TitleOverlay>
        {/* <VideoContainer>

                </VideoContainer> */}
        <TechContainer>
          <TitleContainer>
            <Title1>Technology Used</Title1>
            <Line />
            <StackContainer>
              {detailsArray[workId].tech.map((stack) => {
                return (
                  <Stack>
                    <ImgContainer>
                      <img src={stack.src} alt={stack.name} />
                    </ImgContainer>
                    <h4>{stack.name}</h4>
                  </Stack>
                );
              })}
            </StackContainer>
          </TitleContainer>
        </TechContainer>
        <TimeContainer>
          <TitleContainer>
            <Title1>Time Taken</Title1>
            <Line />
          </TitleContainer>
          <TimeTaken>{detailsArray[workId].timeTaken}</TimeTaken>
          {/* <PathwayContainer>
                        <First>
                            <NumberContainer>
                                <p>0</p>
                            </NumberContainer>
                            <LineSmall />
                            <AbsoluteText className="absolute-text">
                                Wireframe, UI, Primary Stage of Project
                            </AbsoluteText>
                        </First>
                        <Second>
                            <NumberContainer>
                                <p>10</p>
                            </NumberContainer>
                            <LineSmall />
                            <AbsoluteText className="absolute-text">
                                Final Stage of Project, Initial Testing
                            </AbsoluteText>
                        </Second>
                        <Third>
                            <NumberContainer>
                                <p>20</p>
                            </NumberContainer>
                            <LineSmall />
                            <AbsoluteText className="absolute-text">
                            Final Testing, Production Ready Code Deployed
                            </AbsoluteText>
                        </Third>
                        <Fourth>
                            <NumberContainer>
                                <p>30</p>
                            </NumberContainer>
                        </Fourth>
                    </PathwayContainer> */}
        </TimeContainer>
        <ClientContainer>
          <TitleContainer>
            <Title1>Client Review</Title1>
            <Line />
          </TitleContainer>
          <CommentContainer>
            <CommentSection>
              <QuoteContainer>
                <img src={quoteIcon} alt="Quote Icon" />
              </QuoteContainer>
              <ClientComment>
                {detailsArray[workId].review.content}
              </ClientComment>
              <div>
                <Name>{detailsArray[workId].review.name}</Name>
                <Desig>{detailsArray[workId].review.designation}</Desig>
              </div>
            </CommentSection>
            {/* <PictureSection>
                            <img src={detailsArray[workId].review.imgSrc} alt="Client"/>
                        </PictureSection> */}
          </CommentContainer>
        </ClientContainer>
      </Main>
      <Footer />
    </WorkPageContainer>
  );
};

export default WorkPage;
