import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import lifepointsImg from "../images/lifepoints.png";
import dviewImg from "../images/dview1.png";
import dna4goodWebImg from "../images/dna4good-web.jpg";
import ununuImg from "../images/ununu.png";
import myvstatsImg from "../images/myvstats.png";
import bitfiImg from "../images/bitfi.png";

const WorkPageContainer = styled.section``;
const Main = styled.section`
  padding: 10rem 8rem 0 8rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  @media screen and (max-width: 900px) {
    padding: 10rem 3rem 0 3rem;
  }
`;
const Card = styled.div`
  background-color: #fff;
  height: 250px;
  width: 95%;
  border-radius: 2rem;
  margin-bottom: 10rem;
  position: relative;
  display: flex;
  align-items: flex-end;
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: 900px) {
    height: 550px;
    width: 100%;
    margin-bottom: 10rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  width: calc(100% - 290px);
  height: 100%;
  @media screen and (max-width: 900px) {
    width: 100%;
    height: calc(100% - 260px);
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  background-color: #666af6;
  height: 300px;
  width: 350px;
  top: 50%;
  border-radius: 2rem;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url(${ununuImg}); */
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 50%;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
  img {
    height: 100%;
    width: 100%;
    display: none;
  }
  @media screen and (max-width: 900px) {
    top: -5rem;
    left: 50% !important;
    transform: translate(-50%);
  }
`;

const FloatingTabButton = styled(Link)`
  text-decoration: none;
  align-self: center;
  padding: 1.5rem 2rem;
  background-color: var(--background-color-4);
  box-shadow: 0 0 1rem rgba(233, 79, 55, 0.66);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5rem;
  letter-spacing: var(--spacing-p);
  color: var(--color-1);
  font-size: 1.6rem;
  text-decoration: none;
  @media screen and (max-width: 700px) {
    font-size: 1.4rem;
    font-weight: 500;
  }
`;

const Title = styled.p`
  font-size: 2.2rem;
  letter-spacing: var(--spacing-p);
  @media screen and (max-width: 700px) {
    font-size: 2.2rem;
    text-align: center;
  }
`;
const Description = styled.p`
  font-size: clamp(1.2rem, 5vw, 1.6rem);
  letter-spacing: var(--spacing-p);
  margin-bottom: 2rem;
  text-align: center;
  @media screen and (max-width: 700px) {
    font-size: clamp(1.2rem, 20vw, 1.6rem);
    text-align: center;
  }
`;

const WorksPage = () => {
  const workArr = [
    {
      name: "Lifepoints",
      description:
        "Interact (like, dislike, video views) with the Lifepoints app. Collect points and exchange them for attractive rewards. This turns your social media activity into something you can touch and experience.",
      subhead: "UX Design, App Development",
      imageUrl: lifepointsImg,
    },
    // {
    //     name: "Dview",
    //     description: "Unified Data Platform Powered by dView's the Data Pipeline Engine. The data pipeline divides each data stream into smaller chunks that it processes in parallel, conferring extra computing power.",
    //     subhead: "Big Data Platform",
    //     imageUrl: dviewImg
    // },
    {
      name: "Dna4Good",
      description:
        "A web application and a progressive web app to generate covid certificates. The app supports generation of certificates for CWA, Vaccinated, Recovered cases",
      subhead: "Web Development, PWA",
      imageUrl: dna4goodWebImg,
    },
    {
      name: "Bitfi",
      description:
        "A cryptocurrency mobile application that runs on both android and ios. In this app a user can buy, sell and manage their crypto assets by using INR currency. Users can also see real time data of any crypto.",
      subhead: "Cryptocurrency, App Development",
      imageUrl: bitfiImg,
    },
    {
      name: "Ununu",
      description:
        "Built for musicians, by musicians, ununu is a collaborative workspace that empowers you to share projects, track versions & communicate with multiple contributors all in one place.",
      imageUrl: ununuImg,
    },
    {
      name: "MyVStats",
      description:
        "MyVStats, an app where you can keep track of your Fifa scores, tournaments, wins/loses and weekened tournament with built in OCR, compare results with your friends, add new friends. This app is available on both iOS and Android",
      imageUrl: myvstatsImg,
    },
  ];
  return (
    <WorkPageContainer>
      <Navbar />
      <Main>
        {workArr.map((work, index) => {
          return (
            <Card
              key={`card-${index}`}
              style={{
                alignSelf: index % 2 === 0 ? "flex-end" : "flex-start",
                justifyContent: index % 2 === 0 ? "flex-end" : "flex-start",
              }}
            >
              <ImageContainer
                style={{
                  left: index % 2 === 0 && "-6rem",
                  right: index % 2 !== 0 && "-6rem",
                }}
                bg={work.imageUrl}
              />
              <Content>
                <Title>{work.name}</Title>
                <Description>{work.description}</Description>
                <FloatingTabButton to={`/work/${index}`}>
                  Know more
                </FloatingTabButton>
              </Content>
            </Card>
          );
        })}
      </Main>
      <Footer />
    </WorkPageContainer>
  );
};

export default WorksPage;
