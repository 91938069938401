import { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import footerLogo from "../images/footer-logo.png";
import overlayBottom from "../images/overlay-bottom.svg";
import heartIcon from "../images/heart.png";

const FooterContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  /* padding: 0 8rem; */
  margin-top: 8rem;
  position: relative;
  overflow: hidden;
  /* border: 1px solid green; */
  @media screen and (max-width: 700px) {
    margin-top: 8rem;
  }
`;
const NameContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 14rem;
  @media screen and (max-width: 700px) {
    margin-top: 8rem;
  }
`;

const CompanyName = styled.h1`
  color: var(--color-5);
  font-weight: 700;
  font-size: 2.4rem;
  letter-spacing: var(--spacing-p);
  border-bottom: 1px solid var(--color-2);
  padding-bottom: 0.5rem;
  /* width: calc(100% - 95px); */
  @media screen and (max-width: 700px) {
    font-size: 1.6rem;
  }
`;
const LogoContainer = styled.div`
  width: 80px;
  margin-right: 1.5rem;
  img {
    width: 100%;
  }
  @media screen and (max-width: 700px) {
    width: 60px;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 9.5rem;

  a {
    font-size: 1.8rem;
    color: var(--color-2);
    text-decoration: none;
    letter-spacing: var(--spacing-p);
    @media screen and (max-width: 700px) {
      font-size: 1.2rem;
    }
  }
  @media screen and (max-width: 700px) {
    padding-left: 6.5rem;
  }
`;
const ServicesConsultationContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const Services = styled.div`
  padding-left: 4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 2.5rem;
  width: 50%;
  border-right: 2px solid var(--color-6);
  h3 {
    font-size: 2.2rem;
    font-weight: 700;
    color: var(--color-6);
    letter-spacing: var(--spacing-p);
    margin-bottom: 10px;
    @media screen and (max-width: 700px) {
      font-size: 1.4rem;
    }
  }

  ul {
    list-style: none;

    li {
      font-size: 1.9rem;
      font-weight: 400;
      color: var(--color-6);
      letter-spacing: var(--spacing-p);
      margin-bottom: 5px;
      @media screen and (max-width: 700px) {
        font-size: 1.2rem;
      }
    }
  }

  @media screen and (max-width: 700px) {
    padding-left: 3rem;
    padding-right: 1rem;
    border-right: 1px solid var(--color-6);
  }
`;
const Consultation = styled(Services)`
  border-right: none;
  padding-left: 17.5rem;
  @media screen and (max-width: 1100px) {
    padding-left: 4rem;
  }
  @media screen and (max-width: 700px) {
    padding-left: 4rem;
  }
`;

const heartBeat = keyframes`
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
}
`;

const CopyrightSection = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 8rem;
  h3 {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 400;
    color: #2a2a2a;
    img {
      width: 17px;
      animation: ${heartBeat} 1.5s ease-in-out infinite both;
      position: relative;
      margin: 0 0.5rem;
    }
    @media screen and (max-width: 700px) {
      font-size: 1.2rem;
    }
  }
  @media screen and (max-width: 700px) {
    margin-bottom: 2rem;
    margin-top: 3rem;
  }
`;
const OverlayContainer = styled.div`
  position: absolute;
  z-index: -1;
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  height: 100%;
  img {
    width: 100%;
  }
`;

const BackGreen = styled.div`
  width: 100%;
  height: 100%;
  background-color: #44bba4;
  position: relative;
  & > div {
    width: 100%;
    height: 1rem;
    background-color: #44bba4;
    position: absolute;
    top: -0.5rem;
  }
`;

const PadContainer = styled.div`
  padding: 0 8rem;
  @media screen and (max-width: 700px) {
    padding: 0 3rem;
  }
`;

const CookieSection = styled.div`
  background: rgba(20, 20, 20, 0.8);
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 14px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0;
  box-sizing: border-box;
  z-index: 10;
  p {
    letter-spacing: 1px;
    max-width: 80%;
  }
  @media (max-width: 900px) {
    padding: 10px 15px;
    align-items: top;
    p {
      letter-spacing: 1px;
      max-width: 60%;
    }
  }
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  min-width: 140px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    min-width: 130px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
    min-width: 120px;
  }
`;

const Button = styled.button`
  background-color: transparent;
  border: 1px solid white;
  outline: none;
  color: white;
  padding: 1rem;
  letter-spacing: 1px;
  border-radius: 3rem;
  margin-right: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border: 1px solid #44bba4;
    color: #44bba4;
  }
`;

const CrossIcon = styled(Button)`
  border: none;
  font-size: 2rem;
  cursor: pointer;
  &:hover {
    border: none;
    color: unset;
  }
`;

const retrieveCookie = (name) => {
  let cookie_value = "",
    current_cookie = "",
    name_expr = name + "=",
    all_cookies = document.cookie.split(";"),
    n = all_cookies.length;

  for (let i = 0; i < n; i++) {
    current_cookie = all_cookies[i].trim();
    if (current_cookie.indexOf(name_expr) === 0) {
      cookie_value = current_cookie.substring(
        name_expr.length,
        current_cookie.length
      );
      break;
    }
  }

  return cookie_value;
};

const Footer = () => {
  const [isCookieConcent, setCookieConcent] = useState(
    retrieveCookie("acumenCogCookieConcent")
  );

  const hideCookieConcent = () => {
    setCookieConcent(true);
  };

  const addConcent = () => {
    let date = new Date();
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    let expires = date.toUTCString();
    document.cookie = `acumenCogCookieConcent=true;path=/;expires=${expires};`;
    setCookieConcent(retrieveCookie("acumenCogCookieConcent"));
  };

  const getCurrentYear = () => {
    var d = new Date();
    return d.getFullYear();
  };

  return (
    <FooterContainer>
      <OverlayContainer>
        <img src={overlayBottom} alt="Footer Overlay" />
        <BackGreen>
          <div></div>
        </BackGreen>
      </OverlayContainer>
      <PadContainer>
        <NameContainer>
          <LogoContainer>
            <img src={footerLogo} alt="Logo" />
          </LogoContainer>
          <div>
            <CompanyName>
              AcumenCog, <br />
              Axel-springer-platz 3 Hamburg&nbsp;20355&nbsp;DE
            </CompanyName>
          </div>
        </NameContainer>
        <LinksContainer>
          <Link to="/contact">Our Offices</Link>
          {/* <Link to="/imprint">Imprint</Link> */}
        </LinksContainer>
        <ServicesConsultationContainer>
          <Services>
            <h3>Services</h3>
            <ul>
              <li>UI & UX Design</li>
              <li>Software Development</li>
              <li>Web App Develpoment</li>
              <li>Mobile App Development</li>
            </ul>
          </Services>
          <Consultation>
            <h3>Consultation</h3>
            <ul>
              <li>Data Analytics</li>
              <li>Support & Services</li>
              <li>Resource Outsourcing</li>
              <li>Cloud & DevOps Solution</li>
            </ul>
          </Consultation>
        </ServicesConsultationContainer>
        <CopyrightSection>
          <h3>
            Made with <img src={heartIcon} alt="heart icon" /> in Hamburg
          </h3>
          <h3>©2019-{getCurrentYear()} AcumenCog. All Rights Reserved.</h3>
        </CopyrightSection>
      </PadContainer>
      {!isCookieConcent ? (
        <CookieSection>
          <p size="xs" align="left">
            <span color="active" style={{ display: "inline" }}>
              AcumenCog
            </span>
            &nbsp;follows GDPR regulation. We use cookies to ensure you get the
            best experience on our website.
          </p>
          <ActionContainer>
            <Button size="s" onClick={addConcent}>
              That's Fine
            </Button>
            <CrossIcon onClick={hideCookieConcent} size="s">
              &#10005;
            </CrossIcon>
          </ActionContainer>
        </CookieSection>
      ) : null}
    </FooterContainer>
  );
};

export default Footer;
