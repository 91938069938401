import styled from "styled-components";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { pageAnimation } from "../animation";
import Navbar from "../components/Navbar";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import Recognition from "../components/Recognition";
import Vision from "../components/Vision";
import Help from "../components/Help";
// import Review from '../components/Review'
import Clients from "../components/Clients";
import Work2 from "../components/Work2";
import Expertise from "../components/Expertise";
import overlaySvg from "../images/overlay1.svg";
import overlay4Svg from "../images/overlay4.svg";
import heroPng from "../images/heroPng.svg";

const HomePageContainer = styled(motion.div)`
  width: 100%;
  /* background-image: url(${overlaySvg}); */
  /* background-color: red; */
  /* background-repeat: no-repeat; */
  /* background-size: 100% 100%; */
  background-position: 0 0;
  position: relative;
  height: 100vh;

  @media screen and (max-width: 1100px) {
    /* background-color: red; */
  }
`;

const OverlayContainer = styled.div`
  width: 100vw;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  object-fit: cover;
  img {
    width: 100%;
    z-index: -1;
    @media screen and (max-width: 900px) {
      width: 350%;
    }
  }

  & > .main-overlay {
    position: absolute;
    bottom: 20%;
    @media screen and (max-width: 900px) {
      bottom: 40%;
      left: -35%;
    }
  }

  & > .heroPng {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0.8;
    @media screen and (max-width: 1100px) {
      top: 65%;
      right: 50%;
      transform: translate(50%, -50%);
    }
    @media screen and (max-width: 900px) {
      top: 60%;
    }
    @media screen and (max-width: 700px) {
      top: 54%;
    }

    & > img {
      width: 40vw;
      @media screen and (max-width: 1100px) {
        width: 58vw;
      }
      @media screen and (max-width: 900px) {
        width: 75vw;
        opacity: 1;
      }
      @media screen and (max-width: 700px) {
        width: 90vw;
        opacity: 1;
      }
    }
  }

  & > .gap-fill1 {
    display: none;
    @media screen and (max-width: 1300px) {
      display: block;
    }
  }
  & > .gap-fill2 {
    position: absolute;
    top: 5rem;
    left: 0.1rem;
    z-index: -1;
  }
`;

const Main = styled.main`
  width: 100%;
  overflow-x: hidden;
`;
// const Frame1 = styled(motion.div)`
//     position: fixed;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100vh;
//     background-color: var(--background-color-1);
//     z-index: 5;
// `

// const Frame2 = styled(Frame1)`
//     background-color: var(--background-color-2);
// `
// const Frame3 = styled(Frame1)`
//     background-color: var(--color-2);
// `
// const Frame4 = styled(Frame1)`
//     background-color: var(--color-4);
// `
const WorkSectionContainer = styled.section`
  /* height: 100vh; */
  padding: 0 8rem;
  margin: 8rem 0;
  /* background-color: green; */
  @media screen and (max-width: 900px) {
    padding: 0 4rem;
    margin: 4rem 0;
  }
`;
const HelpSectionContainer = styled(WorkSectionContainer)``;
// const ReviewsSectionContainer = styled(WorkSectionContainer)``
const ClientSectionContainer = styled(WorkSectionContainer)`
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0;
`;
const RecognitionSectionContainer = styled(WorkSectionContainer)``;
const VisionSectionContainer = styled(WorkSectionContainer)``;
const ExpertiseSectionContainer = styled(WorkSectionContainer)``;
const ContactSectionContainer = styled.section`
  padding: 0 8rem;
  margin: 8rem 0;
  @media screen and (max-width: 900px) {
    padding: 0 4rem;
    margin: 4rem 0;
  }
`;

const HomePage = () => {
  return (
    <HomePageContainer
      className="yamero"
      initial="hidden"
      animate="show"
      variants={pageAnimation}
    >
      <Helmet>
        <title>AcumenCog | Digital Solution Company</title>
        <meta
          name="description"
          content="AcumenCog provides complete software development services for business, from backend to frontent to consultations"
        />
      </Helmet>
      <OverlayContainer>
        {/* <img src={overlay3Svg} className="gap-fill1" alt="" />
                <img src={overlay3Svg} className="gap-fill2" alt="" /> */}
        <img src={overlay4Svg} className="main-overlay" alt="" />
        {/* <OverlayInlineSvg className="main-overlay"/> */}
        <div className="heroPng">
          <img src={heroPng} alt="" />
        </div>
      </OverlayContainer>
      <Navbar />
      {/* <motion.div 
                initial="hidden"
                animate="show"
                variants={sliderContainer}
            >
                <Frame3 variants={slider}></Frame3>
                <Frame1 variants={slider}></Frame1>
                <Frame2 variants={slider}></Frame2>
                <Frame4 variants={slider}></Frame4>
            </motion.div> */}
      <Main>
        <HeroSection />
        <HelpSectionContainer>
          <Help />
        </HelpSectionContainer>
        <WorkSectionContainer>
          <Work2 />
        </WorkSectionContainer>
        {/* <ReviewsSectionContainer>
                    <Review />
                </ReviewsSectionContainer> */}
        <ClientSectionContainer>
          <Clients />
        </ClientSectionContainer>
        <RecognitionSectionContainer>
          <Recognition />
        </RecognitionSectionContainer>
        {/* <VisionSectionContainer>
          <Vision />
        </VisionSectionContainer> */}
        <ExpertiseSectionContainer>
          <Expertise />
        </ExpertiseSectionContainer>
        <ContactSectionContainer id="contact">
          <Contact />
        </ContactSectionContainer>
        <Footer />
      </Main>
    </HomePageContainer>
  );
};
export default HomePage;
