import UiUx from "../images/sup5.svg";
import CloudDevOps from "../images/cloud.svg";
//import CloudSolutions from "../images/cloud.png";
import MobAppDev from "../images/mobile-dev.svg";
import WebDev from "../images/web-dev1.svg";
import AppDev from "../images/sup6.svg";
import salesforceSvg from "../images/sup9.svg";
//import RPA from "../images/rpa-dev.svg";
import BigData from "../images/data.svg";
import AiMlDev from "../images/support.svg";
import ConsultationImg from "../images/consultation.svg";

export default [
  {
    name: "UI & UX Design",
    subname: "UI & UX Design",
    overview: [
      "Design-led innovation around the clock.",
      "AcumenCog has always paid close attention to the needs of end-users. Our team of UX/UI experts tailors our design services to meet your requirements and build applications with the perfect look and feel for web, mobile, and desktop.",
    ],
    img: UiUx,
  },
  {
    name: "Mobile Application",
    subname: "Mobile Application",
    overview: [
      "iOS",
      "AcumenCog delivers solutions for the entire Apple product range, including iPhones, iPads, Apple Watches, and Apple TV apps, following Apple’s Human Interface Guidelines and best practices using XCode and Swift programming language.",
      "",
    ],
    description: [
      "ANDROID",
      "To support a growing device park of Android-based devices and reach out to 2.5 billion users, AcumenCog android app developers leverage our expertise in Kotlin and Java, as well as specific Android development tools, to deliver secure apps, featuring fault-free performance and impeccable interface.",
      "",
      "CROSS PLATFORM",
      "Write once, run everywhere!",
      "AcumenCog mobile engineers are well versed in Flutter and React native for rapid implementation of cross-platform mobile apps that preserve a native look and feel on the major mobile platform while keeping a single codebase and cutting down app development time and costs.",
    ],
    img: MobAppDev,
  },
  {
    name: "Web Application",
    subname: "Web Application",
    overview: [
      "AcumenCog boasts a team of highly experienced full-stack agile developers who will work closely with you, from concept to final product, to ensure that the best technologies power your chosen functionalities.",
    ],
    img: WebDev,
  },
  {
    name: "Software Application",
    subname: "Custom Software Application",
    overview: [
      "Stay ahead of the competition with a customized enterprise software built to suit your business challenges and goals. Our experienced team will work with you to design, develop, and implement a fully custom solution to help you move forward.",
      "Get one-stop software product from scratch completely tailored to your business needs.",
    ],
    img: AppDev,
  },
  {
    name: "Salesforce",
    subname: "Salesforce",
    overview: [
      "Stay ahead and improve your business performance by integrating Salesforce cloud. Salesforce offers a well structured organisation for managing your customer relationships all in one place.",
      "Salesforce helps companies target different audiences, set scores and alerts based on customer’s activity and maintain relationships.",
      "Benefits of integrating salesforce cloud in your digital solutions:",
    ],
    details: [
      "Trustworthy reporting",
      "Dashboards that visually showcase data",
      "Improved messaging with automation",
      "Proactive service",
      "Efficiency enhanced by automation",
      "Simplified collaboration",
    ],
    // overview: [
    //     "Stay ahead of the competition with a customized enterprise software built to suit your business challenges and goals. Our experienced team will work with you to design, develop, and implement a fully custom solution to help you move forward.",
    //     "Get one stop software product from scratch completely tailored to your business needs."
    // ],
    img: salesforceSvg,
  },
  {
    name: "Cloud & DevOps solutions",
    subname: "Cloud & DevOps solutions",
    overview: [
      "AcumenCog will perform a through analysis of the existing infrastructure, development processes, and state of automation for bottlenecks and stability/scalability/security risks.",
      "You will get an audit report and a plan on how to apply cutting-edge DevOps techniques and achieve the organization’s business goals such as:",
      "",
    ],
    details: [
      "Cut infrastructure costs",
      "Reduce the costs of manual work spent on deployment and configuration",
      "Eliminate the errors caused by human factor",
      "Reduce downtime, reach 24x7 availability with failover infrastructure",
      "Increase performance of the application",
      "Minimize security risks",
    ],
    img: CloudDevOps,
  },
  // Automations
  // {

  // },
  {
    name: "Data Analytics",
    subname: "Data Analytics",
    overview: [
      "AcumenCog offer services to businesses to help them develop and implement a comprehensive Big Data strategy. This can help them optimize every possible process and streamline them together to drive the maximum possible revenues.",
      "",
    ],
    description: [
      "We leverage powerful tools  and also develop custom big data software to deliver values across the entire enterprise.",
      "The AcumenCog  team is skilled enough to support you through the entire development cycle of the Big Data solution that you are looking for.",
      "Whatever is your need or goal, our aim remains the same to provide you end-to-end process to help you achieve your long term business Intelligence and Analytics goals.",
    ],
    img: BigData,
  },
  {
    name: "Support & Services",
    subname: "Support & Services",
    overview: [
      "Acumencog provides 24x7 support services to our customers for their day to day technical activities to keep their business running smoothly. We have dedicated support teams for different technologies. We also help our customers on-demand basis. We help our clients by providing marketing services and analyzing the future demands. Our support team takes care of responsibility for our customers to stand out from their competition and changing future demands.",
    ],
    img: AiMlDev,
  },
  {
    name: "Consultation",
    subname: "Consultation",
    overview: [
      "Convert your ideas into digital solutions. For this we give you possible approaches along with its merits & demerits and help you to choose the best approach available for your business need. We also provide solutions for improving an existing product.",
    ],
    img: ConsultationImg,
  },
  // {
  //     name: "RPA solutions",
  //     content: "A software development company, with a difference. We build deep tech SAAS products and platforms. Our experts in Analytics, Machine Learning & Integrations, build upon learnings and frameworks from a decade of launching 205+ products. We understand the process, passion and hard work it takes to launch and support a growing product.",
  //     img: RPA
  // },
  // {
  //     name: "Resource Outsourcing",
  //     overview: [
  //         "AcumenCog  helps startups and corporate clients to build secure, trusted and decentralised environments using Blockchain . Our extensive experience helps our clients in adopting the newest technologies to optimize processes, remove bottlenecks, adapt, innovate and lead through Blockchain solutions.",
  //         ""
  //     ],
  //     details: [
  //         "Smart Contract Development:  AcumenCog provides smart contract development and auditing services for projects based on both public and private blockchain networks.",
  //         "Token Offering:  Blockchain-powered tokenization and digital assets are massive trends on track to disrupt a number of industries, creating new business models along the way."
  //     ],
  //     content: "A software development company, with a difference. We build deep tech SAAS products and platforms. Our experts in Analytics, Machine Learning & Integrations, build upon learnings and frameworks from a decade of launching 205+ products. We understand the process, passion and hard work it takes to launch and support a growing product.",
  //     img: BlockChain
  // }
];
