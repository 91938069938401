import styled, {keyframes} from 'styled-components'
import dna4goodLogo from '../images/dna4good-logo.png'
import lifepointsLogo from '../images/lifepoints-logo.png'
import cravestoneLogo from '../images/cravestone.png'
import dviewLogo from '../images/dview-logo.png' 
import teamGoLogo from "../images/team-go.png"
import ununuLogo from "../images/ununu-logo.png"
import myvstatsLogo from "../images/myvstats-logo.png"
import goprotozLogo from "../images/goprotoz.png"
import freelanceJrLogo from "../images/freelance-junior.png"
import { motion } from 'framer-motion'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const ClientContainer = styled.section`
    padding: 5rem 0;
`

const TitleContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    div {
        margin-bottom: 0;
    }
`

const Title = styled.h1`
    font-size: 4.5rem;
    font-weight: 700;
    color: var(--color-2);
    margin: 0 2rem;
    @media screen and (max-width: 900px) {
        font-size: 3rem;
        text-align: center;
    }
    @media screen and (max-width: 900px) {
        font-size: 3rem;
    }
`


const ImageContainer = styled.div`
    width: 20rem;
    margin: 0 20px;
    /* position: absolute; */
    right: -25rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    img {
        width: 100%;
        /* height: 100%; */
        opacity: 0.9;
        object-fit: cover
    }
    .small-logo {
        width: 70px;
    }
    @media screen and (max-width: 700px) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
    }
`
const LogoName = styled.p`
    font-size: 24px;
    color: #606060;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 5px;
    letter-spacing: 1px;
    text-align: center;
    @media screen and (max-width: 700px) {
        font-size: 18px;
    }
`


const Main = styled.section`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .container-with-dots {
        height: 100%;
        width: 100%;
    }
    .react-multi-carousel-list {
        .react-multi-carousel-track {
            .react-multi-carousel-item {
                display: flex;
                justify-content: center;
                align-items: center;
            } 
        }
    }
    @media screen and (max-width: 700px) {
       flex-flow: row nowrap;
       align-content: center;
       justify-content: center;
    }
`

const Line = styled.div`
    margin: 0 0 30px;
    width: 18rem;
    height: 0.8rem;
    background-color: var(--background-color-4);
    @media screen and (max-width: 900px) {
        height: 0.6rem;
        width: 13rem;
        margin: 0 0 1.5rem;
    }
`

const Client = () => {
    return (
        <ClientContainer>
            <TitleContainer>
                <Line />
                <Title>Our Happy Clients</Title>
                <Line />
            </TitleContainer>
            <Main>
                {/* <ImageContainer className="client1">
                    <img className="cravestone" src={cravestoneLogo} alt="" />
                </ImageContainer>
                <ImageContainer className="client2">
                    <img src={dna4goodLogo} alt="" />
                </ImageContainer>
                <ImageContainer className="client3">
                    <img src={dviewLogo} alt="" />
                </ImageContainer>
                <ImageContainer className="client4">
                    <img src={lifepointsLogo} alt="" />
                </ImageContainer>
                <ImageContainer className="client5">
                    <img src={lifepointsLogo} alt="" />
                </ImageContainer>
                <ImageContainer className="client6">
                    <img src={lifepointsLogo} alt="" />
                </ImageContainer> */}

        <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlay
        autoPlaySpeed={100}
        centerMode={true}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        // customTransition="all 3s linear"
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        transitionDuration={2000}
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        focus={false}
        swipeable={false}
        responsive={responsive}
        >
            <ImageContainer>
                <img className="small-logo" src={cravestoneLogo} alt="" />
                <LogoName>Curvestone</LogoName>
            </ImageContainer>
            <ImageContainer>
                <img src={dna4goodLogo} alt="dna4good" />
            </ImageContainer>
            <ImageContainer>
                <img src={dviewLogo} alt="dview" />
            </ImageContainer>
            <ImageContainer>
                <img className="small-logo" src={lifepointsLogo} alt="lifepoints" />
                <LogoName>Lifepoints</LogoName>
            </ImageContainer>
            <ImageContainer>
                <img className="small-logo" src={teamGoLogo} alt="team go" />
                <LogoName>Team Go</LogoName>
            </ImageContainer>
            <ImageContainer>
                <img className="small-logo" src={goprotozLogo} alt="go protoz" />
                <LogoName>GoProtoz</LogoName>
            </ImageContainer>
            <ImageContainer>
                <img className="small-logo" src={myvstatsLogo} alt="myvstats" />
                <LogoName>myVStats</LogoName>
            </ImageContainer>
            <ImageContainer>
                <img className="small-logo" src={freelanceJrLogo} alt="freelancer junior" />
                <LogoName>Freelancer Junior</LogoName>
            </ImageContainer>
            <ImageContainer>
                <img className="small-logo" src={ununuLogo} alt="ununu" />
                <LogoName>Ununu</LogoName>
            </ImageContainer>
        </Carousel>;

            </Main>
        </ClientContainer>
    )
}

export default Client