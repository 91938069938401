import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import companyLogo from "../images/logo-white@2x.png";
import menuIcon from "../images/hamburger-menu.svg";
import crossIcon from "../images/cross.svg";
import { useEffect, useState } from "react";

const NavbarContainer = styled(motion.nav)`
  /* display: grid;
    grid-template-columns: repeat(16, 150px); */
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) =>
    props.pathname === "/" ? "transparent" : "var(--background-color-5)"};
  padding: 0 8rem;
  height: 10rem;
  position: relative;
  @media screen and (max-width: 1100px) {
    /* display: none; */
  }

  @media screen and (max-width: 700px) {
    height: 6rem;
    padding: 0 3rem;
  }
`;
const LogoContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  width: 22rem;
  height: 5.9rem;
  img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 700px) {
    width: 12rem;
  }
  /* grid-column: 1 / 3; */
  /* background-color: aquamarine; */
`;

const LinksContainer = styled.div`
  /* grid-column: 7 / -1; */
  /* background-color: aliceblue; */
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1100px) {
    display: none;
  }
  /* width: 55rem; */
`;
const NavLinks = styled(Link)`
  font-size: 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* color: var(--color-6);  */
  color: #fff;
  font-family: var(--lato);
  font-weight: 700;
  text-decoration: none;
  letter-spacing: var(--spacing-p);
  margin-right: 5rem;
  position: relative;
  padding: 1.2rem 0;
  /* border: 1px solid white; */
`;

const LanguageButton = styled.button`
  font-size: 1.5rem;
  cursor: pointer;
  color: #fff;
  font-family: var(--lato);
  font-weight: 700;
  text-decoration: none;
  letter-spacing: var(--spacing-p);
  margin-right: 0;
  border: 1px solid white;
  padding: 1.2rem;
  border-radius: 1rem;
  transition: all 0.3s ease;
  background-color: transparent;
  z-index: 1;
  @media screen and (min-width: 700px) {
    &:hover {
      background-color: white;
      color: #3ca692;
    }
  }
  &:active {
    background-color: rgb(255 255 255 / 0.7);
  }
`;

const ContactNavLink = styled(NavLinks)`
  background-color: var(--background-color-1);
  width: 12rem;
  font-size: 1.6rem;
  height: 4.5rem;
  border-radius: 1rem;
`;

const Line = styled(motion.div)`
  height: 0.4rem;
  width: 100%;
  background-color: var(--background-color-1);
  position: absolute;
  left: 0;
  bottom: 0;
  @media screen and (max-width: 1100px) {
    height: 0.8rem;
    background-color: var(--background-color-4);
  }
`;
const Overlay = styled.div`
  background: ${(props) =>
    props.pathname === "/"
      ? "transparent"
      : "linear-gradient(to right, var(--overlay-1), var(--overlay-2) 150%)"};
  opacity: 0.27;
  position: absolute;
  height: 100%;
  width: 52%;
  right: 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 4.9% 100%);
`;

const Hamburger = styled.div`
  cursor: pointer;
  z-index: 2;
  @media screen and (min-width: 1101px) {
    display: none;
  }
  @media screen and (max-width: 700px) {
    width: 1.7rem;
  }
  img {
    width: 100%;
    height: 100%;
  }
`;
const HamburgerMenu = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  background-color: #44bba4;
  width: 50%;
  height: 100vh;
  z-index: 4;

  @media screen and (min-width: 1100px) {
    display: none;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;
const MenuOverlay = styled(motion.div)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.611);
  backdrop-filter: blur(2px);
  z-index: 3;
  top: 0;
  left: 0;
  @media screen and (min-width: 1100px) {
    display: none;
  }
`;

const MenuHeader = styled.div`
  height: 8rem;
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
  z-index: 4;
  width: 100%;
  position: relative;
  .mobile-lang {
    display: none;
    @media screen and (max-width: 1101px) {
      display: block;
      margin-left: 2rem;
      padding: 5px;
      font-size: 12px;
      font-weight: 400;
      border-radius: 5px;
      text-decoration: none;
      position: absolute;
      left: 21rem;
    }
    @media screen and (max-width: 700px) {
      left: 15rem;
    }
  }
  & > img {
    cursor: pointer;
  }
`;

const MenuLogoContainer = styled(LogoContainer)`
  justify-content: flex-start;
  & > img {
    width: 80%;
  }
  @media screen and (max-width: 700px) {
    & > img {
      width: 100%;
    }
  }
`;

const MenuContainer = styled.div`
  height: calc(100% - 8rem);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  /* padding-left: 7rem; */
  position: relative;
`;
const MenuLinks = styled(NavLinks)`
  font-size: 3.5rem;
  color: #333;
  margin-bottom: 1.5rem;
`;

const CopyrightSection = styled.p`
  position: absolute;
  transform: rotateZ(270deg);
  right: -10rem;
`;

const overlay = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.1,
    },
  },
  exit: { opacity: 0, transition: { duration: 0.2 } },
};
const hamburger = {
  hidden: { opacity: 0, x: "50%" },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
    },
  },
  exit: { opacity: 0, x: "50%", transition: { duration: 0.2 } },
};

const Navbar = () => {
  const { pathname } = useLocation();
  const [menu, setMenu] = useState(false);
  const [language, setLanguage] = useState(true);
  const [curLanguage, setCurLanguage] = useState("EN");
  const { t } = useTranslation();

  const getCurrentYear = () => {
    var d = new Date();
    return d.getFullYear();
  };

  useEffect(() => {
    language === true ? setCurLanguage("EN") : setCurLanguage("DE");
  }, [language, curLanguage]);
  return (
    <NavbarContainer pathname={pathname}>
      <AnimatePresence exitBeforeEnter>
        {menu && (
          <>
            <MenuOverlay
              onClick={() => {
                setMenu(() => !menu);
              }}
              variants={overlay}
              initial="hidden"
              animate="show"
              exit="exit"
            />
            <HamburgerMenu
              variants={hamburger}
              initial="hidden"
              animate="show"
              exit="exit"
            >
              <MenuHeader>
                <Overlay pathname={pathname} />
                <HeaderContainer>
                  {/* <LanguageButton className="mobile-lang"
                                onClick={() => {
                                    setLanguage((prevState) => !prevState )
                                }}
                            >
                                {curLanguage}
                            </LanguageButton> */}
                  <MenuLogoContainer to="/">
                    <img src={companyLogo} alt="Company Logo" />
                  </MenuLogoContainer>
                  <img
                    src={crossIcon}
                    alt="Menu Cross Icon"
                    onClick={() => {
                      setMenu(() => !menu);
                    }}
                  />
                </HeaderContainer>
              </MenuHeader>
              <MenuContainer>
                <MenuLinks
                  to="/"
                  style={{
                    marginLeft: pathname === "/" ? "6rem" : "7rem",
                    color: pathname === "/" ? "#fff" : "#333",
                  }}
                >
                  {t("navbar.home")}
                  <Line
                    transition={{ duration: 0.75 }}
                    initial={{ width: "0%" }}
                    animate={{ width: pathname === "/" ? "100%" : "0%" }}
                  />
                </MenuLinks>
                <MenuLinks
                  to="/services"
                  style={{
                    marginLeft: pathname === "/services" ? "6rem" : "7rem",
                    color: pathname === "/services" ? "#fff" : "#333",
                  }}
                >
                  {t("navbar.service")}
                  <Line
                    transition={{ duration: 0.75 }}
                    initial={{ width: "0%" }}
                    animate={{
                      width: pathname === "/services" ? "100%" : "0%",
                    }}
                  />
                </MenuLinks>
                <MenuLinks
                  to="/smes"
                  style={{
                    marginLeft: pathname === "/smes" ? "6rem" : "7rem",
                    color: pathname === "/smes" ? "#fff" : "#333",
                  }}
                >
                  {t("navbar.smes")}
                  <Line
                    transition={{ duration: 0.75 }}
                    initial={{ width: "0%" }}
                    animate={{ width: pathname === "/smes" ? "100%" : "0%" }}
                  />
                </MenuLinks>
                <MenuLinks
                  to="/startup"
                  style={{
                    marginLeft: pathname === "/startup" ? "6rem" : "7rem",
                    color: pathname === "/startup" ? "#fff" : "#333",
                  }}
                >
                  {t("navbar.startup")}
                  <Line
                    transition={{ duration: 0.75 }}
                    initial={{ width: "0%" }}
                    animate={{ width: pathname === "/startup" ? "100%" : "0%" }}
                  />
                </MenuLinks>
                <MenuLinks
                  to="/contact"
                  style={{
                    marginLeft: pathname === "/contact" ? "6rem" : "7rem",
                    color: pathname === "/contact" ? "#fff" : "#333",
                  }}
                >
                  {t("navbar.contact")}
                  <Line
                    transition={{ duration: 0.75 }}
                    initial={{ width: "0%" }}
                    animate={{ width: pathname === "/contact" ? "100%" : "0%" }}
                  />
                </MenuLinks>
                <CopyrightSection>
                  •©2019-{getCurrentYear()} ACUMENCOG. ALLRIGHTS RESERVED•
                </CopyrightSection>
              </MenuContainer>
            </HamburgerMenu>
          </>
        )}
      </AnimatePresence>
      <LogoContainer to="/">
        <img src={companyLogo} alt="Company Logo" />
      </LogoContainer>
      <Overlay pathname={pathname} />
      <Hamburger
        onClick={() => {
          setMenu(() => !menu);
        }}
      >
        <img src={menuIcon} alt="Menu Icons" />
      </Hamburger>
      <LinksContainer>
        <NavLinks to="/">
          {t("navbar.home")}
          <Line
            transition={{ duration: 0.75 }}
            initial={{ width: "0%" }}
            animate={{ width: pathname === "/" ? "100%" : "0%" }}
          />
        </NavLinks>
        <NavLinks to="/services">
          {t("navbar.service")}
          <Line
            transition={{ duration: 0.75 }}
            initial={{ width: "0%" }}
            animate={{ width: pathname === "/services" ? "100%" : "0%" }}
          />
        </NavLinks>
        <NavLinks to="/smes">
          {t("navbar.smes")}
          <Line
            transition={{ duration: 0.75 }}
            initial={{ width: "0%" }}
            animate={{ width: pathname === "/smes" ? "100%" : "0%" }}
          />
        </NavLinks>
        <NavLinks to="/startup">
          {t("navbar.startup")}
          <Line
            transition={{ duration: 0.75 }}
            initial={{ width: "0%" }}
            animate={{ width: pathname === "/startup" ? "100%" : "0%" }}
          />
        </NavLinks>
        <ContactNavLink to="contact">{t("navbar.contact")}</ContactNavLink>
        {/* <LanguageButton onClick={() => {
                    setLanguage((prevState) => !prevState )
                }}>
                    {curLanguage}
                </LanguageButton> */}
      </LinksContainer>
    </NavbarContainer>
  );
};
export default Navbar;
