import { useState, useEffect } from 'react';
import styled from 'styled-components'
import {AnimatePresence, motion} from 'framer-motion'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import companyLogo from '../images/logo-white@2x.png'
import vision1 from '../images/vision1.jpg'
import vision2 from '../images/vision2.png'
import vision3 from '../images/vision3.jpg'
import linkedinLogo from '../images/linkedin.svg'
import leftArrow from '../images/left-red.svg'
import rightArrow from '../images/right-red.svg'

const VisionContainer = styled.section`
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    
    transition: 0.3s all ease;
    h1 {
        font-size: 4.5rem;
        font-weight: 700;
        color: var(--color-2);
        margin-bottom: 1rem;
    }
    @media screen and (max-width: 900px) {
        padding-bottom: 0;
    }
`

const ContentContainer = styled.div`
    background-color: var(--background-color-2);
    border-radius: 1rem;
    height: 50rem;

    display: flex;
    flex-flow: row nowrap;

    transition: 0.3s all ease;

    @media screen and (max-width: 900px) {
        flex-flow: column nowrap;
        padding: 3rem 1rem;
        height: max-content;
    }
    
`

const LogoContainer = styled.div`
    width: 13rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        transform: rotateZ(-90deg);
        @media screen and (max-width: 900px) {
            transform: rotateZ(0);
            width: 50%;
        }
    }
    @media screen and (max-width: 1100px) {
        width: 13rem;
    }
    @media screen and (max-width: 900px) {
        width: 100%;
    }
`
const LogoDummyContainer = styled.div`
    width: 13rem;
    height: 50rem;
    @media screen and (max-width: 900px) {
        /* width: 100%; */
        display: none;
    }
`
const PictureDummyContainer = styled.div`
    width: 35rem;

    @media screen and (max-width: 1100px) {
        width: 30rem;
    }
    /* @media screen and (max-width: 900px) {
        display: none;
    } */
`
const ProfileContainer = styled.div`
    width: calc(100% - 48rem);
    display: flex;
    flex-flow: column nowrap;
    padding: 3rem 5rem;
    position: relative;
    @media screen and (max-width: 1100px) {
        width: calc(100% - 43rem);
        padding: 3rem 3rem;
    }
    @media screen and (max-width: 900px) {
        width: 100%;
        padding: 3rem 3rem;
    }
`
const PictureContainer = styled.div`
    position: absolute;
    top: 4rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;;
    @media screen and (max-width: 900px) {
        right: 3.5rem;
        bottom: 0.5rem;
        top: unset;
    }
`
const FullPictureContainer = styled.div`
    width: 30rem;
    height: 30rem;
    border-radius: 0.8rem;
    box-shadow: 0 0 3rem rgba(49, 68, 108, 0.3);
    background-color: var(--background-color-1);
    overflow: hidden;
    object-fit: cover;
    margin: 0 auto;
    /* padding-top: 2.5rem; */
    /* display: none; */
    img {
        width: 100%;
        /* height: 100%; */
        /* height: 100%; */
    }
    @media screen and (max-width: 1100px) {
        width: 30rem;
    }
    @media screen and (max-width: 900px) {
        width: 8rem;
        height: 8rem;
        padding-top: 0;
    }
`
const ThumbPicContainer = styled.div`
    align-self: flex-end;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 1.5rem;
    @media screen and (max-width: 1100px) {
        margin-top: 4.2rem;
    }
` 

const Thumb1 = styled(motion.div)`
    width: 11rem;
    height: 11rem;
    background-color: var(--background-color-1);
    border-radius: 0.8rem;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* padding-top: 1.5rem; */
    transition: all 0.5s;
    img {
        width: 100%;
    }

    @media screen and (max-width: 1100px) {
        width: 8rem;
        height: 8rem;
    }
    @media screen and (max-width: 900px) {
        display: none;
    }
`
const Thumb2 = styled(Thumb1)`
    margin: 0 1rem;
    background-color:  var(--background-color-5);
    opacity: 1;
`
const Thumb3 = styled(Thumb1)`
    background-color:  var(--background-color-4);
    opacity: 1;
`

const Profile = styled.div`
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    @media screen and (max-width: 900px) {
        /* font-size: 3rem !important; */
    }
`
const Name = styled(motion.h1)`
    color: #fff !important;
    text-align: center;
    font-size: clamp(2rem, 2vw, 3.5rem) !important;
    @media screen and (max-width: 900px) {
        order: 2;
        font-size: 1.8rem !important;
        text-align: right;
        letter-spacing: unset;
        padding-right: 9rem;
        margin-bottom: 0 !important;
    }
   
    
`
const Desig = styled(motion.h2)`
    color: #fff;
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 5rem;
    letter-spacing: var(--spacing-p);
    @media screen and (max-width: 900px) {
        order: 3;
        margin-bottom: 1.2rem;
        text-align: right;
        letter-spacing: unset;
        padding-right: 9rem;
        font-size: 1.4rem;
    }
`
const Comment = styled(motion.p)`
    color: #fff;
    font-weight: 500;
    font-size: clamp(1.5rem, 1.6vw , 3.2rem);
    letter-spacing: var(--spacing-p);
    @media screen and (max-width: 900px) {
        order: 1;
        margin-bottom: 3.5rem;
        font-weight: 500;
    }
`
const SocialContainer = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5rem;
    z-index: 1;
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 900px) {
        order: 1;
        right: 0;
        bottom: -4rem;
        justify-content: flex-end;
    }
`
const Social = styled.a`
    height: max-content;
    cursor: pointer;
    display: inline-block;
    width: max-content;
    img {
        width: 100%;
    }
    @media screen and (max-width: 900px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7rem;
    }
    
`

const ButtonGroup = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: max-content;
    position: absolute;
    right: 0;
    bottom: 4rem;
    transform: translate(50%, 0);
    button {
        width: max-content;
        background-color: transparent;
        border: none;
        outline: none;
        width: 2.5rem;
        cursor: pointer;
        &:first-child {
        margin-bottom: 1rem;
    }
        img {
            width: 100%;
        }
    }
    @media screen and (max-width: 900px) {
        right: unset;
        left: -3.5rem;
        bottom: 2rem;
    }
`

const Vision = () => {

    const [activeTree, setActiveTree] = useState(0)
    const [direction, setDirection] = useState(0)

    const intervalFunc = () => {
        setDirection(0)
        setTimeout(() => {
            if(activeTree < workAr.length - 1) {
                setActiveTree(prev => prev + 1)
            } else {
                setActiveTree(0)
            }
        }, 100)
    }

    useEffect(() => {
        const slideInterval = setInterval(intervalFunc, 7000);
        return () => clearInterval(slideInterval)
    })

    const imageArr = [
        {
            image: vision1
        },
        {
            image: vision2
        },
        {
            image: vision3
        },
    ]

    const workAr = [
        {
            head: "Sunil Kumar",
            subhead: "COO",
            linkedIn: "https://www.linkedin.com/in/sunilkhuwal/",
            para: "We, at AcumenCog, do not just build digital solutions, we also build relationships. You would not feel you are left out. We work as a team, every opinion matters. This also applies to our clients. We listen to our clients and develop strategies, we also provide you with what is going to be the best for your business. This helps us in building a smooth relationship with our clients. Every communication plays a key role in AcumenCog."
        },
        {
            head: "Praveen Kumar",
            subhead: "CEO",
            linkedIn: "https://www.linkedin.com/in/praveen-kumar-8b919518b/",
            para: "We help our clients to use digital innovations to further develop existing business models and to build new business opportunities. In addition, we offer holistic and action-oriented consulting from strategy development to implementation control. We rely on a mix of methods from the world of digital business and classic management consulting. The focus is on our clients’ customers as well as their organization and skills necessary to operate."
        },
        {
            head: "Satender Kumar",
            subhead: "CTO",
            linkedIn: "https://www.linkedin.com/in/satendergautam/",
            para: "Everything we do, includes the best of us but we are not stopping at that, we are always growing. We are always focusing on what is coming in the market and choosing the best as per the business requirement. Our coding standard includes clean coding, modular coding, DRY principle in end to end development."
        }
    ]

    const imageVariants = {
        animate: {
            opacity: 1,
            transition: {
                duration: 0.8
            }
        },
        initial: {
            opacity: 0
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.5
            }
        }
    }

    return (
        <VisionContainer>
            <ContentContainer>
                <LogoContainer>
                    <img src={companyLogo} alt="" />
                </LogoContainer>
                <PictureDummyContainer></PictureDummyContainer>
                <ProfileContainer>
                    <Profile>
                    <AnimatePresence>
                        {/* {workAr.map((el, index) => {
                            if (activeTree === index) {
                                return (
                                    <motion style={{position: 'relative'}} variants={variants} animate="animate" initial="initial" exit="exit" key={`workArr${index}`}>
                                        <Name>{el.head}</Name>
                                        <Desig>{el.subhead}</Desig>
                                        <Comment>{el.para}</Comment>
                                    </motion>
                                )
                            } else return ''
                        })} */}
                    </AnimatePresence>
                        {/* <Name>John Doe</Name> */}
                        <Name variants={imageVariants} animate="animate" initial="initial" exit="exit">{workAr[activeTree].head}</Name>
                        <Desig variants={imageVariants} animate="animate" initial="initial" exit="exit">{workAr[activeTree].subhead}</Desig>
                        <Comment variants={imageVariants} animate="animate" initial="initial" exit="exit">
                        {workAr[activeTree].para}

                        </Comment>
                        <SocialContainer>
                            <Social href={workAr[activeTree].linkedIn} target="_blank" rel="noopener noreferrer">
                                <img src={linkedinLogo} alt="LinkedIn Profile" />
                            </Social>
                        </SocialContainer>
                    </Profile>
                    <ButtonGroup>
                        <button onClick={() => {
                        setDirection(1)
                        setTimeout(() => {
                            if(activeTree <= 0) {
                                setActiveTree(workAr.length - 1)
                            } else {
                                setActiveTree(prev => prev - 1)
                            }
                        }, 100)
                    }}>
                            <img src={leftArrow} alt="" />
                        </button>
                        <button onClick={() => {
                        // clearInterval(slideInterval)
                        setDirection(0)
                        setTimeout(() => {
                            if(activeTree < workAr.length - 1) {
                                setActiveTree(prev => prev + 1)
                            } else {
                                setActiveTree(0)
                            }
                        }, 100)
                    }}>
                            <img src={rightArrow} alt="" />
                        </button>
                    </ButtonGroup>
                </ProfileContainer>
            </ContentContainer>
            <PictureContainer>
                <LogoDummyContainer>

                </LogoDummyContainer>
                <div>
                    <FullPictureContainer>
                    <AnimatePresence>
                        {imageArr.map((el, index) => {
                            if (activeTree === index) {
                                return (
                                    <motion.img src={el.image} alt="" key={`imageArr${index}`} variants={imageVariants} animate="animate" initial="initial" exit="exit"/>
                                )
                            } else return ''
                        })}
                    </AnimatePresence>
                    </FullPictureContainer>
                    <ThumbPicContainer>
                        <Thumb1 style={{opacity: activeTree === 0 ? '0.5' : '1'}}>
                            <img src={vision1} alt="sat thumbnail" />
                        </Thumb1>
                        <Thumb2 style={{opacity: activeTree === 1 ? '0.5' : '1'}}>
                            <img src={vision2} alt="sat thumbnail" />
                        </Thumb2>
                        <Thumb3 style={{opacity: activeTree === 2 ? '0.5' : '1'}}>
                            <img src={vision3} alt="sat thumbnail" />
                        </Thumb3>
                    </ThumbPicContainer>
                </div>
            </PictureContainer>
        </VisionContainer>
    )
}

export default Vision