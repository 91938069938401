import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Navbar from '../components/Navbar'
import Contact from '../components/Contact'
import Location from '../components/Location'
import Footer from '../components/Footer'
import { pageAnimation } from '../animation'
import { motion } from 'framer-motion'

const ContactPageContainer = styled(motion.div)`
    width: 100%;
`
const ContactSectionContainer = styled.section`
    padding: 0 8rem;
    margin: 8rem 0;
    @media screen and (max-width: 900px) {
        padding: 0 4rem;
        margin: 4rem 0;
    }
`
const LocationSectionContainer = styled.section`
    padding: 0 8rem;
    margin: 8rem 0;
    @media screen and (max-width: 900px) {
        padding: 0 4rem;
        margin: 4rem 0;
    }
`

const ContactPage = () => {
    return (
        <ContactPageContainer initial="hidden" animate="show" variants={pageAnimation}>
            <Helmet>
                <title>Contact</title>
                <meta name="description" content="Contact page of AcumenCog" />
            </Helmet>
            <Navbar />
            <ContactSectionContainer>
                <Contact />
            </ContactSectionContainer>
            <LocationSectionContainer>
                <Location />
            </LocationSectionContainer>
            <Footer />
        </ContactPageContainer>
    )
}

export default ContactPage