import styled from "styled-components";
import { motion } from "framer-motion";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import mobileIconWhite from "../images/mobile-white.svg";
import mobileIconGreen from "../images/mobile-green.svg";
import softwareIconWhite from "../images/software-white.svg";
import softwareIconGreen from "../images/software-green.svg";
import uiIconWhite from "../images/ui-white.svg";
import uiIconGreen from "../images/ui-green.svg";
import webIconWhite from "../images/web-white.svg";
import webIconGreen from "../images/web-green.svg";
import lifepointsWork from "../images/lifepoints.png";
import dviewWork1 from "../images/dview1.png";
import myvstatsWork from "../images/myvstats.png";
import ununuWork from "../images/ununu.png";
import bitfiImg from "../images/bitfi.png";
import dna4goodImg from "../images/dna4good-web.jpg";
import { useState } from "react";
import { useHistory, Link } from "react-router-dom";

const HelpContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  border-radius: 1rem;
  h1 {
    font-size: 4.5rem;
    font-weight: 700;
    color: var(--color-2);
    margin-bottom: 1rem;
  }

  p {
    font-size: 2rem;
    font-weight: 400;
    color: var(--color-2);
    margin-bottom: 2.5rem;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 50rem;
  display: flex;
  flex-flow: row nowrap;
  background-color: #fff;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
  /* background-color: var(--background-color-5); */

  ul {
    list-style: none;
  }

  .control-dots {
    display: none;
  }

  .carousel-root {
    width: 100%;
    height: 100%;
  }
`;

const TabContainer = styled.div`
  overflow-x: hidden;
  /* display: flex;
    flex-flow: row nowrap;
    justify-content: center; */

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 25rem));
  grid-row-gap: 3rem;
  grid-column-gap: 3rem;
  grid-auto-flow: row;
  justify-content: center;
  /* align-content: center; */

  width: 100%;
  margin-bottom: 2.5rem;

  @media screen and (max-width: 700px) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    grid-column-gap: 0;
  }
`;

const FloatingTabButton = styled(Link)`
  text-decoration: none;
  align-self: center;
  padding: 1.5rem 3rem;
  background-color: var(--background-color-4);
  box-shadow: 0 0 3rem rgba(233, 79, 55, 0.66);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5rem;
  letter-spacing: var(--spacing-p);
  color: var(--color-1);
  font-size: 2rem;
  text-decoration: none;
  @media screen and (max-width: 700px) {
    font-size: 1.4rem;
    font-weight: 500;
  }
`;

const Tab = styled.div`
  /* width: 45rem; */
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  /* border: ${(props) =>
    props.current === true ? `2px solid #666AF6` : "2px solid transparent"}; */
  border-radius: 2rem;
  padding: 0.5rem 0 0.5rem 2rem;
  @media screen and (max-width: 700px) {
    padding: 0;
  }
  h2 {
    color: #333;
    font-size: 1.6rem;
    letter-spacing: var(--spacing-p);
    /* border: 1px solid red; */
    border: ${(props) =>
      props.current === true ? `2px solid #44BBA4` : "2px solid transparent"};
    box-shadow: ${(props) =>
      props.current === true ? `0 3px 20px rgba(68, 187, 163, 0.16)` : "none"};
    background-color: ${(props) =>
      props.current === true ? `#fff` : "transparent"};
    height: 6rem;
    border-radius: 0 3rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4rem;
    padding-right: 2rem;
    z-index: -1;
    transition: all 0.5s ease;
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  div {
    min-width: 6rem;
    width: 6rem;
    height: 6rem;
    background: ${(props) => (props.current === true ? `#44BBA4` : `#fff`)};
    color: ${(props) => (props.current === true ? `#fff` : `#44BBA4`)};
    /* background: ${(props) => `url(${props.bg}), green`}; */
    background-repeat: no-repeat;
    background-position: 50%;
    box-shadow: ${(props) =>
      props.current === true ? `0 3px 20px rgba(68, 187, 163, 0.16)` : "none"};
    border-radius: 50%;
    margin-right: -3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    font-size: 24px;
    font-weight: 700;

    @media screen and (max-width: 700px) {
      width: 4rem;
      height: 4rem;
      min-width: 4rem;
      margin-right: 0;
      background-size: 60%;
      font-size: 16px;
    }
  }
`;

const SeeMore = styled(Tab)``;

const ContentSection = styled.div`
  /* background-color: var(--background-color-1); */
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 1rem;
  /* overflow: hidden; */
  div {
    height: 80%;
    background-color: #ffffff81;
    backdrop-filter: blur(15px);
    box-shadow: 0 0 10px var(--shadow-1);
    border: 1px solid #fff;
    border-radius: 0.8rem;
    width: 130%;
    position: absolute;
    right: 5rem;
    @media screen and (max-width: 700px) {
      height: 87%;
      width: 80%;
      padding: 0 2rem !important;
      right: unset;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(15px);
    }
  }
  @media screen and (max-width: 1100px) {
    width: 100%;
  }
  @media screen and (max-width: 700px) {
    /* display: none; */
    /* width: 0; */
    position: absolute;
  }
`;
const ContentPicture = styled.div`
  width: 50%;
  height: 100%;
  clip-path: polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%);
  background-color: var(--background-color-2);
  z-index: 2;
  /* border-radius: 1rem; */
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem 0 0 1rem;
    @media screen and (max-width: 700px) {
      border-radius: 1rem;
    }
  }
  @media screen and (max-width: 700px) {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    width: 100%;
  }
`;

const FloatingTabs = styled(motion.div)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 10rem;
  z-index: ${(props) => (props.current === true ? "2" : "1")};

  h1 {
    font-size: 2.2rem;
    letter-spacing: var(--spacing-p);
    @media screen and (max-width: 700px) {
      font-size: 2.2rem;
      text-align: center;
    }
  }

  p {
    font-size: 1.8rem;
    letter-spacing: var(--spacing-p);
    margin-bottom: 2rem;
    @media screen and (max-width: 700px) {
      font-size: 1.6rem;
      text-align: center;
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 4rem;
  div {
    margin-bottom: 0;
  }
`;
const Title = styled.h1`
  font-size: 4.5rem;
  font-weight: 700;
  color: var(--color-2);
  margin-right: 2rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 900px) {
    font-size: 3rem !important;
  }
`;

const Line = styled.div`
  margin: 0 0 30px;
  width: 18rem;
  height: 0.8rem;
  background-color: var(--background-color-4);
  @media screen and (max-width: 900px) {
    height: 0.6rem;
    width: 13rem;
    margin: 0 0 1.5rem;
  }
`;

const CircleB = styled.div`
  border-radius: 50% !important;
  width: 17rem !important;
  height: 17rem !important;
  position: absolute !important;
  left: 7rem !important;
  bottom: 3rem !important;
  background-color: var(--background-color-5) !important;
  border: none !important;
  box-shadow: none !important;
  backdrop-filter: none !important;
  @media screen and (max-width: 700px) {
    display: none;
  }
`;
const CircleS = styled(CircleB)`
  width: 12rem !important;
  height: 12rem !important;
  left: unset;
  left: 52rem !important;
  bottom: 34rem !important;
  background-color: var(--background-color-4) !important;
  @media screen and (max-width: 700px) {
    display: none;
  }
`;

const Subhead = styled.h2`
  font-size: 2rem;
  color: #333;
  font-weight: 400;
  letter-spacing: var(--spacing-p);
  margin-bottom: 2rem;
  text-align: center;
  @media screen and (max-width: 700px) {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 1rem;
  }
`;

const Work2 = () => {
  const [activeTree, setActiveTree] = useState(0);
  const history = useHistory();

  const imageArr = [
    {
      image: lifepointsWork,
    },
    // {
    //   image: dviewWork1,
    // },
    {
      image: dna4goodImg,
    },
    {
      image: bitfiImg,
    },
    {
      image: ununuWork,
    },
    {
      image: myvstatsWork,
    },
  ];
  const TabArr = [
    {
      image1: uiIconWhite,
      image2: uiIconGreen,
      head: "LIFEPOINTS.DE",
    },
    // {
    //   image1: softwareIconWhite,
    //   image2: softwareIconGreen,
    //   head: "DVIEW.IO",
    // },
    {
      image1: softwareIconWhite,
      image2: softwareIconGreen,
      head: "DNA4GOOD",
    },
    {
      image1: softwareIconWhite,
      image2: softwareIconGreen,
      head: "BITFI",
    },
    {
      image1: webIconWhite,
      image2: webIconGreen,
      head: "UNUNU",
    },
    {
      image1: mobileIconWhite,
      image2: mobileIconGreen,
      head: "MYVSTATS",
    },
  ];

  const ContentArr = [
    {
      para: "Interact (like, dislike, video views) with the Lifepoints app. Collect points and exchange them for attractive rewards. This turns your social media activity into something you can touch and experience.",
      subhead: "UX Design, App Development",
      head: "LIFEPOINTS",
    },
    // {
    //   para: "Unified Data Platform Powered by dView's the Data Pipeline Engine. The data pipeline divides each data stream into smaller chunks that it processes in parallel, conferring extra computing power.",
    //   subhead: "Big Data Platform",
    //   head: "DVIEW",
    // },
    {
      para: "A web application and a progressive web app to generate covid certificates. The app supports generation of certificates for CWA, Vaccinated, Recovered cases",
      subhead: "Web Development, PWA",
      head: "DNA4GOOD",
    },
    {
      para: "A cryptocurrency mobile application that runs on both android and ios. In this app a user can buy, sell and manage their crypto assets by using INR currency. Users can also see real time data of any crypto.",
      subhead: "Cryptocurrency, App Development",
      head: "BITFI",
    },
    {
      para: "Built for musicians, by musicians, ununu is a collaborative workspace that empowers you to share projects, track versions & communicate with multiple contributors all in one place.",
      subhead: "Web App Development",
      head: "UNUNU",
    },
    {
      para: "MyVStats, an app where you can keep track of your Fifa scores, tournaments, wins/loses and weekened tournament with built in OCR, compare results with your friends, add new friends. This app is available on both iOS and Android",
      subhead: "Ionic, Angular & Cordova Native App Development",
      head: "MYVSTATS",
    },
  ];

  const renderTabs = () => {
    return (
      <>
        {TabArr.map((el, index) => {
          return (
            <Tab
              className="cl"
              key={`TabArr${index}`}
              onClick={() => setActiveTree(index)}
              bg={activeTree === index ? el.image1 : el.image2}
              current={activeTree === index ? true : false}
            >
              <div>{`#${index + 1}`}</div>
              <h2>{el.head}</h2>
            </Tab>
          );
        })}
        <Tab
          title="See more of our works"
          onClick={() => {
            history.push("/works");
          }}
        >
          <div>#6</div>
          <h2
            style={{ backgroundColor: "#fff", borderRadius: "0 3rem 3rem 0" }}
          >
            See More Works...
          </h2>
        </Tab>
      </>
    );
  };

  const variants = {
    initial: { opacity: 0, scale: 0.9, transition: { duration: 0.3 } },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.2,
        duration: 0.3,
      },
    },
    exit: {
      transition: {
        duration: 1,
      },
    },
  };
  const imageVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <HelpContainer>
      <TitleContainer>
        <Title>Work</Title>
        <Line />
      </TitleContainer>
      {/* <p>
                Build your solution from scratch or extend your team faster with a trusted IT partner.
            </p> */}
      <TabContainer>{renderTabs()}</TabContainer>
      <ContentContainer>
        <ContentPicture>
          {imageArr.map((el, index) => {
            if (activeTree === index) {
              return (
                // <motion.img src={el.image} alt="" key={`imageArr${index}`} variants={imageVariants} animate="animate" initial="initial" exit="exit"/>
                <motion.img
                  src={el.image}
                  variants={imageVariants}
                  initial="initial"
                  animate={activeTree === index ? "animate" : "initial"}
                  current={activeTree === index ? true : false}
                  exit="exit"
                  alt=""
                />
              );
            } else return "";
          })}
          {/* <img src={helpImage} alt="" /> */}
        </ContentPicture>
        <ContentSection>
          <CircleB></CircleB>
          <CircleS></CircleS>
          {ContentArr.map((el, index) => {
            return (
              <FloatingTabs
                key={`ContentArr${index}`}
                variants={variants}
                initial="initial"
                exit="exit"
                animate={activeTree === index ? "animate" : "initial"}
                current={activeTree === index ? true : false}
              >
                <h1>{el.head}</h1>
                <Subhead>{el.subhead}</Subhead>
                <p>{el.para}</p>
                <FloatingTabButton to={`/work/${index}`}>
                  Know More
                </FloatingTabButton>
              </FloatingTabs>
            );
          })}
        </ContentSection>
      </ContentContainer>
    </HelpContainer>
  );
};

export default Work2;
