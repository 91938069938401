import { Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { ThemeProvider } from "styled-components";
import HomePage from "./pages/HomePage";
// import ServicesPage from './pages/ServicesPage';
import Services from "./pages/Services";
import SmesPage from "./pages/SmesPage";
import StartUpPage from "./pages/StartUpPage";
import ContactPage from "./pages/ContactPage";
import ImprintPage from "./pages/ImprintPage";
import theme from "./theme";
import servicesData from "./constants/services";
import ScrollToTop from "./components/ScrollToTop";
import WorkPage from "./pages/WorkPage";
import WorksPage from "./pages/WorksPage";

const App = () => {
  return (
    <>
      <ScrollToTop />
      <AnimatePresence exitBeforeEnter>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route
              exact
              path="/services"
              render={(props) => <Services {...props} data={servicesData} />}
            />
            <Route exact path="/works" component={WorksPage} />
            <Route exact path="/work/:workId" component={WorkPage} />
            <Route exact path="/smes" component={SmesPage} />
            <Route exact path="/startup" component={StartUpPage} />
            <Route exact path="/contact" component={ContactPage} />
            {/* <Route exact path="/imprint" component={ImprintPage} /> */}
          </Switch>
        </ThemeProvider>
      </AnimatePresence>
    </>
  );
};

export default App;
