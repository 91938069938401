import styled from 'styled-components'
import webIcon from '../images/web-icon.svg'
import infraIcon from '../images/infra.svg'
import toolsIcon from '../images/tools.svg'
import htmlIcon from '../images/html.svg'
import jsIcon from '../images/js.svg'
import reactIcon from '../images/react.svg'
import javaIcon from '../images/java.svg'
import awsIcon from '../images/aws.svg'
import nodeIcon from '../images/node.svg'
import golangIcon from '../images/golang.svg'
import pythonIcon from '../images/python-brands.svg'
import angularIcon from '../images/angular.svg'
import ionicIcon from '../images/ionic.svg'
import oracleIcon from '../images/oracle.svg'
import xmppIcon from '../images/xmpp.svg'
import salesforceIcon from '../images/salesforce.svg'
import tsIcon from '../images/ts.svg'
import mysqlIcon from '../images/mysql.svg'

const ExpertiseContainer = styled.section`

`

const Main = styled.section`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
        display: none;
    }
`

const Mobile = styled.div`
    display: none;
    @media screen and (max-width: 900px) {
        display: flex;
        background: linear-gradient(to right bottom,#44BBA4, #3A9E8A);
        flex-flow: column nowrap;
        padding: 2rem;
        border-radius: 0.8rem;
    }
`

const MobileWeb = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 23rem;
`
const MobileBackend = styled(MobileWeb)`
    & > .tech-stack {
        border-top: 1px solid white; 
        border-bottom: 1px solid white; 
    }
`
const MobileOthers = styled(MobileWeb)``

const MainLogo = styled.div`
    border-right: 1px solid white;
    width: 7rem;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    padding-right: 1rem;
    img {
        width: 80%;
    }
`
const TechStack = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    @media screen and (max-width: 700px) {
        justify-content: flex-start;
    }
`

const Tech = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: center;
    height: 7rem;
    width: 7rem;
    /* align-self: baseline; */
    @media screen and (max-width: 700px) {
        width: 6rem;
    }
    div {
        margin-bottom: 1rem;
    }
    p {
        /* font-size: 12px; */

    }
`


const Web = styled.div`
    position: relative;
    width: 30%;
    background: linear-gradient(to right bottom,#44BBA4, #3A9E8A);
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba(68, 187, 163, 0.20);
    /* height: 67.7rem; */

    /* padding */
    /* height: 30rem; */
    /* padding: 0 5rem 7rem 5rem; */
`
const Infrastructure = styled(Web)`
    margin-top: 5rem;
    height: 68.7rem;

`
const Others = styled(Web)`
    margin-bottom: 0;

`

const IconContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    /* position: absolute;
    transform: translate(0, -50%);
    left: 3rem; */
    border-bottom: 2px solid #333;
    padding-bottom: 3rem;

`
const Icon = styled.div`
    border-radius: 50%;
    width: 7.5rem;
    height: 7.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 70%;
    }
`

const Content = styled.div`
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 18rem));
    grid-row-gap: 5rem;
    grid-column-gap: 3rem;
    grid-auto-flow: row;
    justify-content: center;
    align-content: center;

`

const Wrapper = styled.div`
    height: 5rem;
    display: flex;
    align-items: center;

`

const ImageContainer = styled.div`
    height: 5rem;
    width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.5rem;
    img {
        width: 100%;
        height: 100%;
    }
`
const TextContainer = styled.div`
    h4 {
        font-size: 1.8rem;
        color: #fff;
    }
`
const TitleContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 8rem;
    div {
        margin-bottom: 0;
    }
    @media screen and (max-width: 900px) {
        margin-bottom: 4rem;
        div {
            margin-bottom: unset;
        }
    }
`
const Title = styled.h1`
    font-size: 4.5rem;
    font-weight: 700;
    color: var(--color-2);
    margin-right: 2rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 900px) {
        font-size: 3rem !important;
    }
`

const Line = styled.div`
    margin: 0 0 30px;
    width: 18rem;
    height: 0.8rem;
    background-color: var(--background-color-4);
    @media screen and (max-width: 900px) {
        height: 0.6rem;
        width: 13rem;
        margin: 0 0 1.5rem;
    }
`

const ContentWrapper = styled.div`
    padding: 5rem;
`

const Expertise = () => {
    return (
        <ExpertiseContainer>
            <TitleContainer>
                <Title>Technology Expertise</Title>
                <Line />
            </TitleContainer>
            <Main>
                <Web>
                    <ContentWrapper>
                        <IconContainer>
                            <Icon>
                                <img src={webIcon} alt="Web/Mobile Development Icon" />
                            </Icon>
                        </IconContainer>
                        <Content>
                            <Wrapper>
                                <ImageContainer>
                                    <img src={htmlIcon} alt="" />
                                </ImageContainer>
                                <TextContainer>
                                    <h4>HTML/CSS</h4>
                                </TextContainer>
                            </Wrapper>
                            <Wrapper>
                                <ImageContainer>
                                    <img src={jsIcon} alt="" />
                                </ImageContainer>
                                <TextContainer>
                                    <h4>JavaScript</h4>
                                </TextContainer>
                            </Wrapper>
                            <Wrapper>
                                <ImageContainer>
                                    <img src={tsIcon} alt="" />
                                </ImageContainer>
                                <TextContainer>
                                    <h4>TypeScript</h4>
                                </TextContainer>
                            </Wrapper>
                            <Wrapper>
                                <ImageContainer>
                                    <img src={reactIcon} alt="" />
                                </ImageContainer>
                                <TextContainer>
                                    <h4>React</h4>
                                </TextContainer>
                            </Wrapper>
                            <Wrapper>
                                <ImageContainer>
                                    <img src={reactIcon} alt="" />
                                </ImageContainer>
                                <TextContainer>
                                    <h4>React Native</h4>
                                </TextContainer>
                            </Wrapper>
                            <Wrapper>
                                <ImageContainer>
                                    <img src={angularIcon} alt="" />
                                </ImageContainer>
                                <TextContainer>
                                    <h4>Angular</h4>
                                </TextContainer>
                            </Wrapper>
                            <Wrapper>
                                <ImageContainer>
                                    <img src={ionicIcon} alt="" />
                                </ImageContainer>
                                <TextContainer>
                                    <h4>Ionic</h4>
                                </TextContainer>
                            </Wrapper>
                        </Content>                     
                    </ContentWrapper>
                </Web>
                <Infrastructure>
                    <ContentWrapper>
                        <IconContainer>
                            <Icon>
                                <img src={infraIcon} alt="Backened Icon" />
                            </Icon>
                        </IconContainer>
                        <Content>
                            <Wrapper>
                                <ImageContainer>
                                    <img src={javaIcon} alt="" />
                                </ImageContainer>
                                <TextContainer>
                                    <h4>Java</h4>
                                </TextContainer>
                            </Wrapper>
                            <Wrapper>
                                <ImageContainer>
                                    <img src={nodeIcon} alt="" />
                                </ImageContainer>
                                <TextContainer>
                                    <h4>Node</h4>
                                </TextContainer>
                            </Wrapper>
                            <Wrapper>
                                <ImageContainer>
                                    <img src={golangIcon} alt="" />
                                </ImageContainer>
                                <TextContainer>
                                    <h4>Golang</h4>
                                </TextContainer>
                            </Wrapper>
                            <Wrapper>
                                <ImageContainer>
                                    <img src={pythonIcon} alt="" />
                                </ImageContainer>
                                <TextContainer>
                                    <h4>Python</h4>
                                </TextContainer>
                            </Wrapper>
                        </Content>
                    </ContentWrapper>
                </Infrastructure>
                <Others>
                    <ContentWrapper>
                        <IconContainer>
                            <Icon>
                                <img src={toolsIcon} alt="Others Icon" />
                            </Icon>
                        </IconContainer>
                        <Content>
                            <Wrapper>
                                <ImageContainer>
                                    <img src={awsIcon} alt="" />
                                </ImageContainer>
                                <TextContainer>
                                    <h4>AWS</h4>
                                </TextContainer>
                            </Wrapper>
                            <Wrapper>
                                <ImageContainer>
                                    <img src={mysqlIcon} alt="" />
                                </ImageContainer>
                                <TextContainer>
                                    <h4>MySQL</h4>
                                </TextContainer>
                            </Wrapper>
                            <Wrapper>
                                <ImageContainer>
                                    <img src={salesforceIcon} alt="" />
                                </ImageContainer>
                                <TextContainer>
                                    <h4>Salesforce</h4>
                                </TextContainer>
                            </Wrapper>
                            <Wrapper>
                                <ImageContainer>
                                    <img src={oracleIcon} alt="" />
                                </ImageContainer>
                                <TextContainer>
                                    <h4>Oracle</h4>
                                </TextContainer>
                            </Wrapper>
                            <Wrapper>
                                <ImageContainer>
                                    <img src={xmppIcon} alt="" />
                                </ImageContainer>
                                <TextContainer>
                                    <h4>XMPP Chat</h4>
                                </TextContainer>
                            </Wrapper>
                        </Content>
                    </ContentWrapper>
                </Others>
            </Main>
            <Mobile>
                <MobileWeb>
                    <MainLogo>
                        <img src={webIcon} alt="" />
                    </MainLogo>
                    <TechStack>
                        <Tech>
                            <div >
                                <img style={{width: "3rem"}} src={htmlIcon} alt="" />
                            </div>
                            <p>HTML/CSS</p>
                        </Tech>
                        <Tech>
                            <div >
                                <img style={{width: "3rem"}} src={jsIcon} alt="" />
                            </div>
                            <p>Javascript</p>
                        </Tech>
                        <Tech>
                            <div >
                                <img style={{width: "3rem"}} src={tsIcon} alt="" />
                            </div>
                            <p>Typescript</p>
                        </Tech>
                        <Tech>
                            <div >
                                <img style={{width: "3rem"}} src={reactIcon} alt="" />
                            </div>
                            <p>React</p>
                        </Tech>
                        <Tech>
                            <div >
                                <img style={{width: "3rem"}} src={reactIcon} alt="" />
                            </div>
                            <p>React Native</p>
                        </Tech>
                        <Tech>
                            <div >
                                <img style={{width: "3rem"}} src={angularIcon} alt="" />
                            </div>
                            <p>Angular</p>
                        </Tech>
                        <Tech>
                            <div >
                                <img style={{width: "3rem"}} src={ionicIcon} alt="" />
                            </div>
                            <p>Ionic</p>
                        </Tech>
                    </TechStack>
                </MobileWeb>
                <MobileBackend>
                    <MainLogo>
                        <img src={infraIcon} alt="" />
                    </MainLogo>
                    <TechStack className="tech-stack">
                        <Tech>
                            <div >
                                <img style={{width: "3rem"}} src={javaIcon} alt="" />
                            </div>
                            <p>Java</p>
                        </Tech>
                        <Tech>
                            <div >
                                <img style={{width: "3rem"}} src={nodeIcon} alt="" />
                            </div>
                            <p>Node</p>
                        </Tech>
                        <Tech>
                            <div >
                                <img style={{width: "3rem"}} src={golangIcon} alt="" />
                            </div>
                            <p>Golang</p>
                        </Tech>
                        <Tech>
                            <div >
                                <img style={{width: "3rem"}} src={pythonIcon} alt="" />
                            </div>
                            <p>Python</p>
                        </Tech>
                    </TechStack>
                </MobileBackend>
                <MobileOthers>
                    <MainLogo>
                        <img src={toolsIcon} alt="" />
                    </MainLogo>
                    <TechStack>
                        <Tech>
                            <div >
                                <img style={{width: "3rem"}} src={awsIcon} alt="AWS" />
                            </div>
                            <p>AWS</p>
                        </Tech>
                        <Tech>
                            <div >
                                <img style={{width: "3rem"}} src={mysqlIcon} alt="MySQL" />
                            </div>
                            <p>MySQL</p>
                        </Tech>
                        <Tech>
                            <div >
                                <img style={{width: "3rem"}} src={salesforceIcon} alt="Salesforce" />
                            </div>
                            <p>Salesforce</p>
                        </Tech>
                        <Tech>
                            <div >
                                <img style={{width: "3rem"}} src={oracleIcon} alt="Oracle" />
                            </div>
                            <p>Oracle</p>
                        </Tech>
                        <Tech>
                            <div >
                                <img style={{width: "3rem"}} src={xmppIcon} alt="XMPP" />
                            </div>
                            <p>XMPP Chat</p>
                        </Tech>
                    </TechStack>
                </MobileOthers>
            </Mobile>
        </ExpertiseContainer>
    )
}

export default Expertise;