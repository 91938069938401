import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import helpImage from "../images/help.jpg";
import teamIconWhite from "../images/team1-white.svg";
import teamIconGreen from "../images/team1-green.svg";
import mobileIconWhite from "../images/mobile-white.svg";
import mobileIconGreen from "../images/mobile-green.svg";
import softwareIconWhite from "../images/software-white.svg";
import softwareIconGreen from "../images/software-green.svg";
import uiIconWhite from "../images/ui-white.svg";
import uiIconGreen from "../images/ui-green.svg";
import webIconWhite from "../images/web-white.svg";
import webIconGreen from "../images/web-green.svg";
import consultationIconWhite from "../images/consultation-white.svg";
import consultationIconGreen from "../images/consultation-green.svg";
import { useState } from "react";

const HelpContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  border-radius: 1rem;
  h1 {
    font-size: 4.5rem;
    font-weight: 700;
    color: var(--color-2);
    margin-bottom: 1rem;
  }

  p {
    font-size: 2rem;
    font-weight: 400;
    color: var(--color-2);
    margin-bottom: 2.5rem;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 50rem;
  display: flex;
  flex-flow: row nowrap;
  background-color: #fff;
  border-radius: 1rem;
  position: relative;
  /* background-color: var(--background-color-5); */

  ul {
    list-style: none;
  }

  .control-dots {
    display: none;
  }

  .carousel-root {
    width: 100%;
    height: 100%;
  }
`;

const TabContainer = styled.div`
  overflow-x: hidden;
  /* display: flex;
    flex-flow: row nowrap;
    justify-content: center; */

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 25rem));
  grid-row-gap: 3rem;
  grid-column-gap: 3rem;
  grid-auto-flow: row;
  justify-content: center;
  /* align-content: center; */

  width: 100%;
  margin-bottom: 2.5rem;

  @media screen and (max-width: 700px) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    grid-column-gap: 0;
  }
`;
const Tab = styled.div`
  /* width: 45rem; */
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  /* border: ${(props) =>
    props.current === true ? `2px solid #666AF6` : "2px solid transparent"}; */
  border-radius: 2rem;
  padding: 0.5rem 0 0.5rem 2rem;
  transition: 0.3s all ease;
  @media screen and (max-width: 700px) {
    padding: 0;
  }
  h2 {
    color: #333;
    font-size: 1.6rem;
    letter-spacing: var(--spacing-p);
    /* border: 1px solid red; */
    border: ${(props) =>
      props.current === true ? `2px solid #44BBA4` : "2px solid transparent"};
    box-shadow: ${(props) =>
      props.current === true ? `0 3px 20px rgba(68, 187, 163, 0.16)` : "none"};
    background-color: ${(props) =>
      props.current === true ? `#fff` : "transparent"};
    height: 6rem;
    border-radius: 0 3rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4rem;
    padding-right: 2rem;
    z-index: -1;
    transition: 0.3s all ease;
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  div {
    min-width: 6rem;
    width: 6rem;
    height: 6rem;
    background: ${(props) =>
      props.current === true
        ? `url(${props.bg}),#44BBA4`
        : `url(${props.bg}),#fff`};
    /* background: ${(props) => `url(${props.bg}), green`}; */
    background-repeat: no-repeat;
    background-position: 50%;
    box-shadow: ${(props) =>
      props.current === true ? `0 3px 20px rgba(68, 187, 163, 0.16)` : "none"};
    border-radius: 50%;
    margin-right: -3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s all ease;
    @media screen and (max-width: 700px) {
      width: 4rem;
      height: 4rem;
      min-width: 4rem;
      margin-right: 0;
      background-size: 60%;
    }
  }
`;
const ContentSection = styled.div`
  /* background-color: var(--background-color-1); */
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 1rem;
  div {
    height: 80%;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    box-shadow: 0 0 10px var(--shadow-1);
    border: 1px solid #fff;
    border-radius: 0.8rem;
    width: 130%;
    position: absolute;
    left: 5rem;
    @media screen and (max-width: 700px) {
      height: 87%;
      width: 80%;
      padding: 0 2rem !important;
      left: unset;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(15px);
    }
  }
  @media screen and (max-width: 1100px) {
    width: 100%;
  }
  @media screen and (max-width: 700px) {
    /* display: none; */
    /* width: 0; */
    position: absolute;
  }
`;
const ContentPicture = styled.div`
  width: 50%;
  height: 100%;
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
  background-color: #fff;
  border-radius: 1rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 1rem 1rem 0;
    @media screen and (max-width: 700px) {
      border-radius: 1rem;
    }
  }
  @media screen and (max-width: 700px) {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    width: 100%;
  }
`;

const FloatingTabs = styled(motion.div)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 10rem;
  z-index: ${(props) => (props.current === true ? "2" : "1")};

  h1 {
    font-size: 2.2rem;
    letter-spacing: var(--spacing-p);
    @media screen and (max-width: 700px) {
      font-size: 2.2rem;
      text-align: center;
    }
  }

  p {
    font-size: 1.8rem;
    letter-spacing: var(--spacing-p);
    margin-bottom: 2rem;
    @media screen and (max-width: 700px) {
      font-size: 1.6rem;
      text-align: center;
    }
  }
`;

const FloatingTabButton = styled(Link)`
  text-decoration: none;
  align-self: center;
  padding: 1.5rem 3rem;
  background-color: var(--background-color-4);
  box-shadow: 0 0 3rem rgba(233, 79, 55, 0.66);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5rem;
  letter-spacing: var(--spacing-p);
  color: var(--color-1);
  font-size: 2rem;
  text-decoration: none;
  @media screen and (max-width: 700px) {
    font-size: 1.4rem;
    font-weight: 500;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 4rem;
  div {
    margin-bottom: 0;
  }
`;
const Title = styled.h1`
  font-size: 4.5rem;
  font-weight: 700;
  color: var(--color-2);
  margin-right: 2rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 900px) {
    font-size: 3rem !important;
  }
`;

const Line = styled.div`
  margin: 0 0 30px;
  width: 18rem;
  height: 0.8rem;
  background-color: var(--background-color-4);
  @media screen and (max-width: 900px) {
    height: 0.6rem;
    width: 13rem;
    margin: 0 0 1.5rem;
  }
`;

const CircleB = styled.div`
  border-radius: 50% !important;
  width: 17rem !important;
  height: 17rem !important;
  position: absolute !important;
  left: 7rem !important;
  bottom: 3rem !important;
  background-color: var(--background-color-4) !important;
  border: none !important;
  box-shadow: none !important;
  backdrop-filter: none !important;
  @media screen and (max-width: 700px) {
    display: none;
  }
`;
const CircleS = styled(CircleB)`
  width: 12rem !important;
  height: 12rem !important;
  left: unset;
  left: 52rem !important;
  bottom: 34rem !important;
  background-color: var(--background-color-5) !important;
  @media screen and (max-width: 700px) {
    display: none;
  }
`;

const Help = () => {
  const [activeTree, setActiveTree] = useState(0);
  const TabArr = [
    {
      image1: uiIconWhite,
      image2: uiIconGreen,
      head: "UI & UX Design",
      activeNav: 0,
    },
    {
      image1: softwareIconWhite,
      image2: softwareIconGreen,
      head: "Software Development",
      activeNav: 3,
    },
    {
      image1: webIconWhite,
      image2: webIconGreen,
      head: "Web App Development",
      activeNav: 2,
    },
    {
      image1: mobileIconWhite,
      image2: mobileIconGreen,
      head: "Mobile App Development",
      activeNav: 1,
    },
    {
      image1: teamIconWhite,
      image2: teamIconGreen,
      head: "Support & Services",
      activeNav: 7,
    },
    {
      image1: consultationIconWhite,
      image2: consultationIconGreen,
      head: "Consultation",
      activeNav: 8,
    },
  ];

  const ContentArr = [
    {
      para: "Keep your customers hooked up with your product with the help of our top-notch UI/UX developers. Get an eye-catchy and beautiful UI for your product that guarantees you a great user experience.",
      head: "UI & UX Design",
    },
    {
      para: "Drive innovations in your company with custom software development. Solve unique business challenges to achieve significant results. Accelerate your product growth capacity with a team of expert software engineers.",
      head: "Software Development",
    },
    {
      para: "Get the best of the web practices for your website. Experience fluid design that gives your website a wonderful and trendy look. We follow rules according to w3c to keep your website performant and we also follow the best SEO practices.",
      head: "Web App Development",
    },
    {
      para: "Build iOS or Android mobile applications that will help you to optimize your processes, deeply engage your customers, and gain more profit. The world is moving fastly towards mobile first approach and we help you to keep up on moving future trends.",
      head: "Mobile App Development",
    },
    {
      para: "We provide services to our clients to ensure smooth running of their digital products at every moment. We also assist our clients with the working and processing of the product.",
      head: "Support & Services",
    },
    {
      para: "Convert your ideas into digital solutions. For this we give you possible approaches along with its merits & demerits and help you to choose the best approach available for your business need. We also provide solutions for improving an existing product.",
      head: "Consultation",
    },
  ];

  const renderTabs = () => {
    return TabArr.map((el, index) => {
      return (
        <Tab
          className="cl"
          key={`TabArr${index}`}
          onClick={() => setActiveTree(index)}
          bg={activeTree === index ? el.image1 : el.image2}
          current={activeTree === index ? true : false}
        >
          <div>{/* <img src={el.image} alt="Tab Icons" /> */}</div>
          <h2>{el.head}</h2>
        </Tab>
      );
    });
  };

  const variants = {
    initial: { opacity: 0, scale: 0.9, transition: { duration: 0.3 } },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.2,
        duration: 0.3,
      },
    },
    exit: {
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <HelpContainer>
      <TitleContainer>
        <Title>How can we help you</Title>
        <Line />
      </TitleContainer>
      {/* <p>
                Build your solution from scratch or extend your team faster with a trusted IT partner.
            </p> */}
      <TabContainer>{renderTabs()}</TabContainer>
      <ContentContainer>
        <ContentSection>
          <CircleB></CircleB>
          <CircleS></CircleS>
          {ContentArr.map((el, index) => {
            return (
              <FloatingTabs
                key={`ContentArr${index}`}
                variants={variants}
                initial="initial"
                exit="exit"
                animate={activeTree === index ? "animate" : "initial"}
                current={activeTree === index ? true : false}
              >
                <h1>{el.head}</h1>
                <p>{el.para}</p>
                <FloatingTabButton
                  to={{
                    pathname: "/services",
                    activeNav: TabArr[index].activeNav,
                  }}
                >
                  Know More
                </FloatingTabButton>
              </FloatingTabs>
            );
          })}
        </ContentSection>
        <ContentPicture>
          <img src={helpImage} alt="" />
        </ContentPicture>
      </ContentContainer>
    </HelpContainer>
  );
};

export default Help;
