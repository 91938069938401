import { useState } from 'react'
import styled from 'styled-components'
// import { LoadScript } from "@react-google-maps/api";
// import Maps from './Maps'

const LocationContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;

    h1 {
        font-size: var(--size-h1);
        color: var(--color-2);
        margin-bottom: 1rem;
        @media screen and (max-width: 900px) {
            font-size: 3rem;
        }
    }
    
    p {
        /* font-size: var(--size-h3); */
        font-size: 2rem;
        letter-spacing: var(--spacing-p);
        padding-left: 3px;
        color: var(--color-3);
        margin-bottom: 2.5rem;
    }
`

const TabsContainer = styled.div`
    display: flex;
    margin-bottom: 2.5rem;
    /* flex-flow: row wrap; */
    justify-content: flex-start;
    @media screen and (max-width: 700px) {
        flex-flow: column nowrap;
        margin-bottom: 0;
    }
`
const MapsContainer = styled.div`
    & > div {
        border-radius: 0.5rem;
    }
`
const Tab = styled.div`
    border: 1px solid #44BBA4;
    border-radius: 5rem;
    /* padding: 2rem 4rem; */
    width: 100%;
    max-width: 42rem;
    min-width: 27.8rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;

    div {
        padding: 1.5rem;
        border-radius: 5rem;
        font-size: 2rem;
        font-weight: 700;
        width: 5.5rem;
        height: 5.5rem;
        text-align: center;
        color: #44BBA4;
        background-color: #fff;
        margin-right: 2.5rem;
    }

    h2 {
        font-size: 1.8rem;
        letter-spacing: var(--spacing-p);
    }

    p {
        margin-bottom: 0;
        letter-spacing: var(--spacing-p);
        color: var(--color-2);
        padding: 0;
        font-size: 1.6rem;
    }

    @media screen and (max-width: 700px) {
        margin-bottom: 1.8rem;
        max-width: unset;
    }
    @media screen and (max-width: 700px) {
        margin-bottom: 1.8rem;
        max-width: unset;
    }
`
const Line = styled.div`
    margin: 0 0 30px;
    width: 18rem;
    height: 0.8rem;
    background-color: var(--background-color-4);
    @media screen and (max-width: 900px) {
        height: 0.6rem;
        width: 13rem;
        margin: 0 0 1.5rem;
    }
`
const Iframe  = styled.iframe`
    width: 100%;
`

// const libraries = ["places"];

const Location = () => {
    const [address, setAddress] = useState({add1: "Axel-Springer-Platz 3", add2: '20355 Hamburg', add3: "Germany"})
    const [currentLocation, setCurrentLocation] = useState({lat: 53.552894819838066, lng: 9.98494494971364})
    const [selected, setSelected] = useState('de')

    return (
        <LocationContainer>
            <h1>Location</h1>
            <Line />
            <p>Let's work together. You can find us in:</p>
            <TabsContainer>
                <Tab 
                    style={{marginRight: "2rem", backgroundColor: selected === 'de' ? '#44BBA4' : 'transparent'}}
                    onClick={() => {
                        setCurrentLocation({lat: 53.552894819838066, lng: 9.98494494971364})
                        setAddress({add1: "Axel-Springer-Platz 3", add2: '20355 Hamburg', add3: "Germany"})
                        setSelected('de')
                }}
                >
                    <div>DE</div>
                    <h2>Hamburg, Germany</h2>
                    {/* <p>Axel-springer-platz 3<br />Hamburg 20355 DE</p> */}
                    {/* <p></p> */}
                </Tab>
                <Tab
                    style={{backgroundColor: selected === 'in' ? '#44BBA4' : 'transparent'}}
                    current={selected}
                    onClick={() => {
                        setCurrentLocation({lat: 28.68583378852954, lng: 77.36019126164648 })
                        setAddress({add1: "Sector 3, Shahibabad", add2: "Ghaziabad, U.P.", add3: "India"})
                        setSelected('in')
                    }}
                >
                    <div>IN</div>
                    <h2>New Delhi, India</h2>
                    {/* <p>Grand Plaza, Zero Road,<br />Central Delhi 49744 IN</p> */}
                    {/* <p></p> */}
                </Tab>
            </TabsContainer>
            <MapsContainer>
                {/* <LoadScript googleMapsApiKey='' libraries={libraries}>
                    <Maps currentLocation={currentLocation} address={address}/>
                </LoadScript> */}
                {selected === 'de' ? (
                    <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2370.3160120982784!2d9.982275551477326!3d53.5521260668804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b18f51eb994ed1%3A0xc7ea60e0a1631b2d!2sAcumenCog%20UG!5e0!3m2!1sen!2sin!4v1630466700090!5m2!1sen!2sin" height="600" style={{border: 0}} allowFullScreen="" loading="lazy"></Iframe>
                ) : (
                    <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d528.6279663898398!2d77.36023977484295!3d28.68579356428175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfbcbafbea69d%3A0xbe72a931aba46190!2sShanti%20Kunj%20Appartment!5e0!3m2!1sen!2sin!4v1630481432700!5m2!1sen!2sin" width="800" height="600" style={{border: 0}} allowFullScreen="" loading="lazy"></Iframe>
                )}
            </MapsContainer>
        </LocationContainer>
    )
}

export default Location