import React from "react";
import { withRouter } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { Helmet } from "react-helmet";
import Expand from "react-expand-animated";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import supportBanner from "../images/support-services.svg";
import { motion } from "framer-motion";
import { pageAnimation } from "../animation";

const ServicesSection = styled.div`
  width: 100%;
  height: ${(props) => props.height || "100vh"};
  padding: 0 10%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  padding: 0 50px;
  min-height: 750px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
    min-height: auto;
    padding: 0 30px;
  }

  .inner-services-section {
    display: flex;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
      display: none;
    }
  }

  .inner-services-section-mobile-view {
    display: none;
    padding: 120px 0 60px;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
      display: block;
      padding: 25% 0 0;
    }
  }

  .inner-services-section-heading {
    /* padding: 0 0 0 30px; */
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
      padding: 0;
      font-size: 30px;
    }
  }

  .line {
    margin: 0 0 30px;
    width: 18rem;
    height: 0.8rem;
    background-color: var(--background-color-4);
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
      width: 13rem;
      height: 0.6rem;
      margin: 0 0 15px;
    }
  }

  ul {
    list-style-type: circle;
    color: ${(props) => props.theme.color.primary};
  }
`;

const Image = styled.img`
  width: ${(props) => props.width || ""};
  max-width: ${(props) => props.maxWidth || ""};
  height: ${(props) => props.height || ""};
  border-radius: ${(props) => props.borderRadius || ""};
  max-height: ${(props) => props.maxHeight};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
    margin-bottom: 30px;
  }
`;

const Text = styled.div`
  font-size: ${(props) => props.theme.fontSize.default[props.size]};
  color: ${(props) =>
    props.color ? props.theme.color[props.color] : props.theme.color.primary};
  opacity: ${(props) => props.opacity || 1};
  text-align: ${(props) => props.align || ""};
  font-family: ${(props) => props.fontFamily || "'Montserrat', sans-serif"};
  font-weight: ${(props) => props.weight || ""};
  text-decoration: ${(props) => props.decoration || ""};
  line-height: 1.6em;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
    font-size: 16px;
  }
`;

const ServicesInnerSection = styled(ServicesSection)`
  padding: 0 0 0 50px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    flex-direction: column;
    padding: 180px 0 30px 30px;
    height: auto;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
    padding: 0;
  }
`;

const NavSection = styled.div`
  width: 20%;
  padding-top: 25%;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    width: 30%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
    display: none;
  }
`;

const fade = keyframes`
    0% {
        opacity: 0;
        width: 0;
    }
    100% {
        opacity: 1;
        width: 15px;
        @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
            width: 12px;
        }
    }
`;

const Link = styled(Text)`
  position: relative;
  cursor: pointer;
  display: inline-block;
`;

const NavLink = styled(Link)`
  padding-left: 30px;
  margin-bottom: 30px;
  transition: all 0.2s;
  transform: scale(${(props) => (props.isActive ? 1.15 : 1)});
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    padding-left: 25px;
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    transform: scale(1.15);
  }

  .active-nav {
    left: 0;
    top: 8px;
    width: 15px;
    position: absolute;
    border: solid 2px ${(props) => props.theme.color.active};
    border-radius: 2px;
    animation: ${fade} 0.2s linear;
    background: ${(props) => props.theme.color.active};
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
      width: 12px;
      border: solid 1px ${(props) => props.theme.color.active};
    }
  }
`;

const ServicesContentComp = styled.div`
  width: 50%;
  padding-top: 30%;
  padding-bottom: 20%;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    width: 100%;
    padding: 0;
  }
`;

const ServicesImgContent = styled.div`
  width: 50%;
  box-sizing: border-box;
  margin-left: 50px;
  padding-top: 100px;
  min-height: inherit;
  display: flex;
  flex-flow: column;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    margin-left: 0;
    min-height: auto;
    height: auto;
    margin-top: 50px;
    max-width: 450px;
    width: 100%;
    padding: 0;
  }
`;

const Heading = styled.div`
  /* margin-top: 8rem; */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 3%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding-bottom: 2rem;
  border-bottom: 10px dashed var(--background-color-2);

  @media screen and (max-width: 1100px) {
    border-bottom: 7px dashed var(--background-color-2);
  }
  @media screen and (max-width: 700px) {
    border-bottom: 3px dashed var(--background-color-2);
    padding-bottom: 1rem;
  }

  div {
    width: 20%;
    img {
      width: 100%;
    }
  }
  h1 {
    font-size: clamp(2.2rem, 5vw, 9rem);
    line-height: 80%;
    text-align: center;
    font-family: var(--lato);
    font-weight: 700;
    color: var(--color-5);
    letter-spacing: var(--spacing-h);
  }
`;

const ServicesContent = ({
  name,
  subname,
  img,
  overview,
  description,
  details,
  isOpen,
  toggle,
}) => (
  <React.Fragment>
    <Helmet>
      <title>Services</title>
      <meta
        name="description"
        content="List of the services AcumenCog provides"
      />
    </Helmet>
    <ServicesContentComp>
      <Text
        className="inner-services-section-heading"
        fontFamily={"'Lato', sans-serif"}
        size="xl"
        weight="700"
      >
        {subname}
      </Text>
      <div className="line"></div>
      <Text size="xs" weight="300">
        {overview && overview.length
          ? overview.map((data, index) => (
              <React.Fragment key={index}>
                {data}
                <br />
              </React.Fragment>
            ))
          : null}
      </Text>
      {(description && description.length) || (details && details.length) ? (
        <React.Fragment>
          <Expand open={isOpen}>
            {description && description.length ? (
              <Text size="xs" weight="300">
                {description.map((desc, index) => (
                  <React.Fragment key={index}>
                    {desc}
                    <br />
                  </React.Fragment>
                ))}
              </Text>
            ) : null}
            {details && details.length ? (
              <ul>
                {details.map((detail, index) => (
                  <li key={index}>
                    <Text size="xs" weight="300">
                      {detail}
                    </Text>
                  </li>
                ))}
              </ul>
            ) : null}
          </Expand>
          <Link color="active" size="xs" onClick={toggle}>
            Show {isOpen ? "Less" : "More"}
          </Link>
        </React.Fragment>
      ) : null}
    </ServicesContentComp>
    <ServicesImgContent>
      <Image width="100%" src={img} alt={name} />
    </ServicesImgContent>
  </React.Fragment>
);

const Services = ({ data, location }, props) => {
  const [activeNavSection, setActiveNavSection] = React.useState(0);
  const [openDeailsSection, toggleDetails] = React.useState(null);

  React.useEffect(() => {
    changeNavSection(location.activeNav || 0)();
  }, [location.pathname]);

  const changeNavSection = (index) => () => {
    setActiveNavSection(index);
    toggleDetails(null);
  };

  return (
    <motion.div initial="hidden" animate="show" variants={pageAnimation}>
      <Navbar />
      <Heading>
        <h1>Services&nbsp;&&nbsp;Consultations</h1>
        <div>
          <img src={supportBanner} alt="" />
        </div>
      </Heading>
      <ServicesSection height="auto">
        <NavSection>
          {data.map((navLink, index) => (
            <NavLink
              size={"xss"}
              key={index}
              weight="400"
              isActive={index === activeNavSection}
              color={index === activeNavSection ? "active" : ""}
              onClick={changeNavSection(index)}
            >
              {index === activeNavSection ? (
                <div className="active-nav" />
              ) : null}
              {navLink.name}
            </NavLink>
          ))}
        </NavSection>
        <ServicesInnerSection height="auto" className="inner-services-section">
          <ServicesContent
            name={data[activeNavSection] && data[activeNavSection].name}
            subname={data[activeNavSection] && data[activeNavSection].subname}
            img={data[activeNavSection] && data[activeNavSection].img}
            overview={
              data[activeNavSection] && data[activeNavSection].overview
                ? data[activeNavSection].overview
                : null
            }
            description={
              data[activeNavSection] && data[activeNavSection].description
                ? data[activeNavSection].description
                : null
            }
            details={
              data[activeNavSection] && data[activeNavSection].details
                ? data[activeNavSection].details
                : null
            }
            isOpen={openDeailsSection === activeNavSection}
            toggle={() =>
              toggleDetails(
                openDeailsSection !== null ? null : activeNavSection
              )
            }
          />
        </ServicesInnerSection>
        <ServicesInnerSection
          height="auto"
          className="inner-services-section-mobile-view"
        >
          {data.map((navLink, index) => (
            <ServicesContent
              key={index}
              name={navLink.name}
              subname={navLink.subname}
              img={navLink.img}
              overview={navLink.overview}
              description={navLink.description}
              details={navLink.details ? navLink.details : null}
              isOpen={index === openDeailsSection}
              toggle={() =>
                toggleDetails(openDeailsSection === index ? null : index)
              }
            />
          ))}
        </ServicesInnerSection>
      </ServicesSection>
      <Footer />
    </motion.div>
  );
};

export default withRouter(Services);
