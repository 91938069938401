import styled from 'styled-components'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Helmet from 'react-helmet'
import { pageAnimation } from '../animation'
import { motion } from 'framer-motion'

const SmesPageContainer = styled(motion.div)`


`



const Main = styled.main`
    position: relative;
    padding: 8rem 8rem 0rem 8rem;
    display: flex;
    flex-flow: column nowrap;
    @media screen and (max-width: 700px) {
        padding: 3rem 2rem 0rem 2rem;
    }
`

const Section1 = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 15rem;
    @media screen and (max-width: 1100px) {
        margin-bottom: 10rem;
    }
    @media screen and (max-width: 900px) {
        margin-bottom: 5rem;
    }
    @media screen and (max-width: 700px) {
        margin-bottom: 9rem;
    }
`

const Head1 = styled.h1`
    font-size: 20vw;
    line-height: 80%;
    color: var(--background-color-1);
`
const Head2 = styled.h1`
    text-align: center;
    border-top: 10px dashed var(--background-color-2);
    border-bottom: 10px dashed var(--background-color-2);
    color: var(--background-color-1);
    font-size: 8vw;
    line-height: 95%;
    font-weight: 700;
    position: relative;
    @media screen and (max-width: 700px) {
        border-top: 5px dashed var(--background-color-2);
        border-bottom: 5px dashed var(--background-color-2);
    }
`

const OuterCircle = styled.div`
    width: 22rem;
    height: 22rem;
    border-radius: 50%;
    border: 1.5rem solid var(--background-color-5);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    h1 {
        font-family: var(--lato);
        color: var(--color-1);
        font-size: 2.5rem !important;
        text-align: center;
        letter-spacing: var(--spacing-h);
        text-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
        z-index: 2;
    }

    h2 {
        position: absolute;
        font-family: var(--lato);
        color: var(--color-2);
        font-size: 10rem;
        line-height: 85%;
        @media screen and (max-width: 1100px) {
            font-size: 8rem;
        }
        @media screen and (max-width: 900px) {
            font-size: 7rem;
        }
        @media screen and (max-width: 700px) {
            font-size: 4rem;
        }
    }

    @media screen and (max-width: 1100px) {
        width: 16rem;
        height: 16rem;
        border: 0.8rem solid var(--background-color-5);
    }
    @media screen and (max-width: 900px) {
        width: 13rem;
        height: 13rem;
    }
    @media screen and (max-width: 700px) {
        position: relative;
        width: 11rem;
        height: 11rem;
        border: 0.5rem solid var(--background-color-5);
        order: 1;
        left: unset;
        transform: translate(0);
        margin-bottom: 15px;
    }

    
`
const Section2 = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 18.5rem;
    position: relative;

    div {
        /* width: 31rem;
        height: 31rem; */
    }
    h1 {
        font-size: 3.5rem;
    }
    @media screen and (max-width: 700px) {
        height: max-content;
        margin-bottom: 3rem;
        flex-flow: column nowrap;
    }
`

const Section3 = styled(Section2)`
    justify-content: flex-end;
    align-self: flex-end;
    @media screen and (max-width: 700px) {
        justify-content: flex-start;
    }
`
const Section4 = styled(Section2)`

`
const Section5 = styled(Section3)``
const Section6 = styled(Section2)`

`

const Content2 = styled.div`
    width: 40% !important;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    padding-right: 2rem;
    p {
        font-size: clamp(1.2rem, 1.3vw, 2.6rem);
        /* font-size: 1.75rem; */
        letter-spacing: var(--spacing-p);
        color: var(--color-2);
        @media screen and (max-width: 1100px) {
            /* font-size: 1.6rem; */
        }
        @media screen and (max-width: 700px) {
            font-size: 1.4rem;
            text-align: center;
        }

    }
    .heading {
        color: var(--color-7);
        font-weight: 700;
        font-size: 1.7vw;
        /* font-size: 2.3rem; */
        margin-bottom: 1rem;
        @media screen and (max-width: 1100px) {
            /* font-size: 2rem; */
        }
        @media screen and (max-width: 700px) {
            font-size: 1.8rem;
            text-align: center;
        }
    }
    @media screen and (max-width: 700px) {
        order: 2;
        width: 80% !important;
        padding-right: 0;
        align-items: center;
    }
`

const Content3 = styled(Content2)`
    padding-left: 2rem;
    padding-right: 0;
    @media screen and (max-width: 700px) {
        padding-left: 0;
    }
`

const Quote = styled.p`
    position: absolute;
    left: 50%;
    top: -70%;
    transform: translate(-50%, 0);
    font-size: 28px;
    width: 600px;
    text-align: center;
    @media screen and (max-width: 1100px) {
        font-size: 20px;
    }
    @media screen and (max-width: 700px) {
        width: 360px;
    }
`

const SmesPage = () => {
    return (
        <SmesPageContainer initial="hidden" animate="show" variants={pageAnimation}>
            <Helmet>
                <title>SMEs</title>
                <meta name="description" content="How we help small and medium enterprises" />
            </Helmet>
            <Navbar />
            <Main>
                <Section1>
                    <Head1>5</Head1>
                    <Head2>
                        STEPS&nbsp;FOR&nbsp;HOW
                        <br />
                        WE&nbsp;HELP&nbsp;SMEs
                    </Head2>
                </Section1>
                <Section2>
                    <OuterCircle>
                        <h2>01</h2>
                        <Quote>We support our clients throughout the entire lifecycle of digital innovations.</Quote>
                    </OuterCircle>
                    <Content2>
                        <p className="heading">01. Requirements Understanding</p>
                        <p>We properly document each and every prospect of the client's digital ideas. We go through questions and answers sessions to get every details of the business requirement. We focus entirely on getting every minute details and sketch down the complete requirements.</p>
                    </Content2>
                </Section2>
                <Section3>
                    <Content3>
                        <p className="heading">02. Customized Strategy</p>
                        <p>We help our clients to find the right strategy. Depending on the requirements of client, we design a tailored approach for their business to have a proper digital solution. This gives our clients clear commercial and planning visions.</p>
                        {/* <p>We help our clients to find the right strategic goals and to define the overarching expectations and dependencies. This gives our clients clear commercial and planning guidelines.</p> */}
                    </Content3>
                    <OuterCircle>
                        <h2>02</h2>
                    </OuterCircle>
                </Section3>
                <Section4>
                    <OuterCircle>
                        <h2>03</h2>
                    </OuterCircle>
                    <Content2>
                        <p className="heading">03. 360° Concepts</p>
                        <p>Through our strength in the areas of product design & development activities, infrastructure planning and monitoring. We break down the strategies into tangible solutions and provide testable prototypes to final products.</p>
                        {/* <p>Through our strengths in the areas of user requirements, activity and process design, infrastructure planning and monitoring, we break down strategies into tangible solutions and provide testable prototypes to the final product.</p> */}
                    </Content2>
                </Section4>
                <Section5>
                    <Content3>
                        <p className="heading">04. Implement Management</p>
                        <p>We help our customers in the implementation of their new products in their environments. We also help them to understand the processing and working of their digital products by providing business consultations to make them comfortable with the new product.</p>
                        {/* <p>We also help our customers in the implementation of their digital products. We start parallel or seamlessly with strategy development and business consulting and steer the implementation through all development phases and challenges.</p> */}
                    </Content3>
                    <OuterCircle>
                        <h2>04</h2>
                    </OuterCircle>
                </Section5>
                <Section6>
                    <OuterCircle>
                        <h2>05</h2>
                    </OuterCircle>
                    <Content2>
                        <p className="heading">05. Support & Services</p>
                        {/* <p>With our strengths in the areas of user requirements, activity and process design, infrastructure planning and monitoring, we break down strategies into tangible solutions and provide testable prototypes to the final product.</p> */}
                        <p>After the product development, we provide support and services to our clients for day to day housecleaning work. This keeps their business run smoothly and help them grow. This also helps the clients in two ways, firstly, maximum application uptime is achieved which results in better profits. Secondly, it gives a clear vision for future business enhancements.</p>
                    </Content2>
                </Section6>
            </Main>
            <Footer />
        </SmesPageContainer>
    )
}

export default SmesPage