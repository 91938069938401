import styled from 'styled-components'
// import recognition1 from '../images/recognition-1@2x.png'
// import recognition2 from '../images/recognition-2@2x.png'
// import recognition3 from '../images/recognition-3@2x.png'
// import recognition4 from '../images/recognition-4@2x.png'
// import recognition5 from '../images/recognition-5@2x.png'
// import starsIcon from '../images/stars-group.svg'

const RecognitionContainer = styled.section`

`
const Main = styled.div`
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(27rem, 27rem));
    grid-row-gap: 7rem;
    grid-column-gap: 10rem;
    grid-auto-flow: row;
    justify-content: center;
    align-content: center; */
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-content: center;

`

// const Card = styled.div`
//     background-color: var(--background-color-5);
//     height: 28rem;


//     ul {
//         padding: 0 5rem;
//         li {
//             font-size: 1.4rem;
//             letter-spacing: var(--spacing-p);
//         }

//         li::marker {
//             color: var(--background-color-2);
//             font-size: 2rem;
//         }
//     }
// `

// const Picture1 = styled.div`
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 3rem;
//     img {
//         width: 55%;
//         /* height: 4rem; */
//         object-fit: contain;
//         height: 3.5rem;
//     }
// `

// const Picture2 = styled(Picture1)`
//     img {
//       width: 85%;   
//     }
// `
// const Picture3 = styled(Picture1)`
//     img {
//       width: 100%; 

//       object-fit: cover;  
//     }
// `
// const Picture4 = styled(Picture1)`
//     img {
//       width: 80%;   
//     }
// `
// const Picture5 = styled(Picture1)`
//     img {
//       width: 100%;   
//     }
// `

const TitleContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 8rem;
    div {
        margin-bottom: 0;
    }
    @media screen and (max-width: 900px) {
        margin-bottom: 4rem;
    }
`
const Title = styled.h1`
    font-size: 4.5rem;
    font-weight: 700;
    color: var(--color-2);
    margin-right: 2rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 900px) {
        font-size: 3rem;
    }
`

const Line = styled.div`
    margin: 0 0 30px;
    width: 18rem;
    height: 0.8rem;
    background-color: var(--background-color-4);
    @media screen and (max-width: 900px) {
        height: 0.6rem;
        width: 13rem;
        margin: 0 0 1.5rem;
    }
`

const Recognition1 = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-content: center;

    padding: 1rem;
    @media screen and (max-width: 700px) {
        flex-flow: column nowrap;
        margin-bottom: 1.5rem;
    }
`
const RecogName = styled.div`

    width: 20rem;
    background: linear-gradient(to right,#44BBA4, #3A9E8A);
    box-shadow: 0 0 2rem rgba(68, 187, 163, 0.20);
    border-radius: 0.8rem;
    margin-right: 5rem;
    h1 {
        font-size: 2.5rem;
        color: white;
        padding: 1.5rem;
        @media screen and (max-width: 700px) {
            font-size: 1.8rem;
        }
    }
    @media screen and (max-width: 700px) {
        margin-bottom: 0.5rem;
        width: 15rem;
    }
`
const RecogContent = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 20rem);
    background-color: white;
    border-radius: 1rem;
    position: relative;
    padding: 0 1.5rem 0 1.5rem;
    div {
        border: 1px solid red;
        height: 6rem;
        img {
            /* width: 100%;
            height: 100%; */
        }
    }
    p {
        display: block;
        font-size: 2rem;
        color: #333;
        @media screen and (max-width: 700px) {
            font-size: 1.8rem;
            padding: 1.5rem 0;
        }
    }
    @media screen and (max-width: 700px) {
        width: 100%;
    }
`
// const RecogContentFloat = styled.div`
//     /* display: flex;
//     flex-flow: row nowrap;
//     align-content: center; */
//     width: calc(100% - 20rem);
//     background-color: #ffffff51;
//     backdrop-filter: blur(15px);
//     border-radius: 1rem;
//     padding-left: 3rem; 
//      p {
//         display: flex;
//         flex-flow: row nowrap;
//         align-content: center;
//         font-size: 2.2rem;
//         color: #333;
//         letter-spacing: var(--spacing-p);
//      }
// `
const StarGroup = styled.div`

`


const Recognition = () => {
    return (
        <RecognitionContainer>
            <TitleContainer>
                <Title>Recognition</Title>
                <Line />
            </TitleContainer>
            <Main>
                <Recognition1>
                    <RecogName>
                        <h1>
                            Clutch
                        </h1>
                    </RecogName>
                    <RecogContent>
                            <p>Complete package of software solutions.</p>
                            {/* <div>
                                <img src={starsIcon} alt="Stars Icon" />
                            </div> */}
                    </RecogContent>
                    <StarGroup>
                    </StarGroup>
                </Recognition1>
                <Recognition1>
                    <RecogName>
                        <h1>
                            The Manifest
                        </h1>
                    </RecogName>
                    <RecogContent>
                            <p>Best support offering in IT Field.  </p>
                            {/* <div>
                                <img src={starsIcon} alt="Stars Icon" />
                            </div> */}
                    </RecogContent>
                    <StarGroup>
                    </StarGroup>
                </Recognition1>
                <Recognition1>
                    <RecogName>
                        <h1>
                            Entrepreneur
                        </h1>
                    </RecogName>
                    <RecogContent>
                            <p>Fastest delivery of quality productions apps.</p>
                            {/* <div>
                                <img src={starsIcon} alt="Stars Icon" />
                            </div> */}
                    </RecogContent>
                    <StarGroup>
                    </StarGroup>
                </Recognition1>
                {/* <Card>
                    <Picture1>
                        <img src={recognition1} alt="" srcset="" />
                    </Picture1>
                    <ul>
                        <li>Best support and resources offering in IT Field.  </li>
                        <li>Fastest delivery of quality productions apps.</li>
                        <li>Complete package of software solutions.</li>
                    </ul>
                </Card>
                <Card>
                    <Picture2>
                        <img src={recognition2} alt="" srcset="" />
                    </Picture2>
                    <ul>
                        <li>Best support and resources offering in IT Field.  </li>
                        <li>Fastest delivery of quality productions apps.</li>
                        <li>Complete package of software solutions.</li>
                    </ul>
                </Card>
                <Card>
                    <Picture3>
                        <img src={recognition3} alt="" srcset="" />
                    </Picture3>
                    <ul>
                        <li>Best support and resources offering in IT Field.  </li>
                        <li>Fastest delivery of quality productions apps.</li>
                        <li>Complete package of software solutions.</li>
                    </ul>
                </Card>
                <Card>
                    <Picture4>
                        <img src={recognition4} alt="" srcset="" />
                    </Picture4>
                    <ul>
                        <li>Best support and resources offering in IT Field.  </li>
                        <li>Fastest delivery of quality productions apps.</li>
                        <li>Complete package of software solutions.</li>
                    </ul>
                </Card>
                <Card>
                    <Picture5>
                        <img src={recognition5} alt="" srcset="" />
                    </Picture5>
                    <ul>
                        <li>Best support and resources offering in IT Field.  </li>
                        <li>Fastest delivery of quality productions apps.</li>
                        <li>Complete package of software solutions.</li>
                    </ul>
                </Card> */}
            </Main>
        </RecognitionContainer>
    )
}

export default Recognition