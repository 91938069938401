import styled from "styled-components"
import { motion } from 'framer-motion'
import { fade, titleAnim } from '../animation'
import { useTranslation } from 'react-i18next';
import TextLoop from "react-text-loop";

const HeroSectionContainer = styled.div`
    display: flex;
    width: 100%;
    height: calc(100vh - 10rem);
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`
const Introduction = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-flow: column nowrap;
justify-content: center;
padding-left: 12rem;
z-index: 1;
@media screen and (max-width: 1100px) {
    margin-top: 7.5rem;
    justify-content: flex-start;
    padding-left: 8rem;
    padding-right: 8rem;
}
@media screen and (max-width: 700px) {
    padding-left: 3rem;
    padding-right: 3rem;
}

.introduction-wrapper {
    height: 25rem;
    width: 100%;

    @media screen and (max-width: 1400px) {
        height: 20rem;
    }
    @media screen and (max-width: 1100px) {
        height: 17rem;
    }
    @media screen and (max-width: 900px) {
        height: 80%;

        .heading div {
            width: 100% !important;
        }
    }
}

.heading {
   margin-bottom: 1.5rem;
}

.sub-heading {
    /* width: 60rem; */
    margin-bottom: 1.5rem;
    @media screen and (max-width: 900px) {
        width: 100%;
    }
}
.contact-button {
    margin-bottom: 1.5rem;
    width: fit-content;
    /* box-shadow: 0 3px 3rem rgba(222, 45, 18, 0.354); */
    border-radius: 1rem;
}
div {
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 900px) {
        align-self: center;
    }
    h1 {
        font-size: clamp(4rem, 5vw, 7rem); 
        font-weight: var(--weight-hero);
        color: #050D0B;
        letter-spacing: var(--spacing-h);
        @media screen and (max-width: 700px) {
            font-size: 3rem;
            letter-spacing: unset;
            color: #fff;
            text-align: center;
        }
    }

    h3 {
        font-size: clamp(2rem, 1.5vw, 2.5rem);
        font-weight: var(--weight-h3);
        color: #050D0B;
        letter-spacing: var(--spacing-p);
        padding-left: 3px;
        overflow-wrap: normal;
        @media screen and (max-width: 900px) {
            text-align: center;
        }
        @media screen and (max-width: 700px) {
            letter-spacing: unset;
            color: #fff;
            text-align: center;
        }
    }

    a {
        width: 25rem;
        height: var(--hero-button-height);
        background-color: rgb(233, 79, 55);
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: var(--color-1);
        font-size: 2rem;
        font-weight: 700;
        border-radius: 1rem;

        @media screen and (max-width: 700px) {
            font-size: 1.8rem;
            padding: 1.2rem 3rem;
            width: max-content;
            height: unset;
            font-weight: 500;
        }
    }
}

`
const HeroPictureContainer = styled(motion.div)`
    width: 50%;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    /* z-index: -10; */
    opacity: 0.2;
    img {
        width: 80%;
    }
`
const HeroSection = () => {
    const {t} = useTranslation()
    return (
        <HeroSectionContainer>
            <Introduction>
                <div className="introduction-wrapper">
                    <div className='heading'>
                        <motion.div variants={titleAnim}>
                            <TextLoop interval="6000">
                                <motion.h1 style={{textAlign: "center"}} variants={fade}>{t("home.title1")}</motion.h1>
                                <motion.h1  style={{textAlign: "center"}} variants={fade}>{t("home.title2")}</motion.h1>
                                <motion.h1  style={{textAlign: "center"}} variants={fade}>{t("home.title3")}</motion.h1>
                            </TextLoop>
                        </motion.div>
                    </div>
                    <div className='sub-heading'>
                        <TextLoop interval="6000" noWrap={false}>
                            <motion.h3 variants={titleAnim}>{t("home.subtitle1")}</motion.h3>
                            <motion.h3 variants={titleAnim}>{t("home.subtitle2")}</motion.h3>
                            <motion.h3 variants={titleAnim}>{t("home.subtitle3")}</motion.h3>
                        </TextLoop>
                    </div>

                </div>
                <motion.div className='contact-button'>
                    <motion.a variants={titleAnim} href="#contact">{t("home.hero_button")}</motion.a>
                </motion.div>
            </Introduction>
            <HeroPictureContainer
                variants={fade}
            >
                {/* <motion.img
                    src={heroPng}
                    alt="Hero Picture"
                    variants={photoAnim}
                /> */}
            </HeroPictureContainer>
        </HeroSectionContainer>
    )
}

export default HeroSection