import styled from 'styled-components'
import {motion} from 'framer-motion'
import { Helmet } from 'react-helmet'
import { pageAnimation} from '../animation'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import thinkIllustraton from '../images/think.svg'
import startupIllustraton1 from '../images/sup1.svg'
import startupIllustraton2 from '../images/sup2.svg'
import startupIllustraton3 from '../images/sup3.svg'
import startupIllustraton4 from '../images/sup4.svg'
import startupIllustraton5 from '../images/sup5.svg'
import startupIllustraton6 from '../images/sup6.svg'
import startupIllustraton7 from '../images/sup7.svg'
import startupIllustraton8 from '../images/sup8.svg'
import miniLogo from '../images/mini-logo.png'
import { useTranslation } from 'react-i18next'

const StartUpPageContainer = styled(motion.div)`


`

const Main = styled.main`
    position: relative;
    padding: 8rem 8rem 8rem 8rem;

    @media screen and (max-width: 700px) {
        padding: 3rem;
    }

`

const HeadContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10%;
`
const IllustrationContainer = styled.div`
    /* position: absolute; */
    top: 0;
    right: 0;
    z-index: -1;
    width: 29.72rem;
    img {
        width: 100%;
    }
`

const Heading = styled.div`
    /* margin-top: 8rem; */
    h1 {
        font-size: 8vw;
        line-height: 80%;
        text-align: center;
        font-family: var(--lato);
        font-weight: 700;
        color: var(--color-5);
        letter-spacing: var(--spacing-h);
        /* border-bottom: 5px dashed var(--background-color-2); */
    }
`
const StartUp1 = styled.section`
    /* border-left: 5px dashed var(--line-color-1);
    border-bottom: 5px dashed var(--line-color-1); */
    width: 100%;
    min-height: max-content;
    display: flex;
    flex-flow: row nowrap;
    padding: 0 4rem;
    @media screen and (max-width: 1100px) {
        flex-flow: column nowrap;
    }

    @media screen and (max-width: 700px) {
        padding: 0 2rem;
    }

`
const StartUp2 = styled(StartUp1)``
const StartUp3 = styled(StartUp1)``
const StartUp4 = styled(StartUp1)``
const StartUp5 = styled(StartUp1)``
const StartUp6 = styled(StartUp1)``
const StartUp7 = styled(StartUp1)``
const StartUp8 = styled(StartUp1)`
`

const Text1 = styled.div`
    width: 50%;
    /* height: max-content; */
    min-height: 100%;
    /* min-height: 50rem; */
    border-left: 6px dashed var(--line-color-1);
    padding-bottom: 7rem;
    padding-left: 5rem;
    position: relative;
    h1 {
        font-size: var(--size-hero);
        font-family: var(--abril);
        color: var(--color-2);
        letter-spacing: var(--spacing-h);
        margin-bottom: 2rem;

        @media screen and (max-width: 700px) {
            font-size: 2rem;
            margin-bottom: 0.7rem;
            padding-top: 0.5rem;
        }
    }

    h2 {
        font-size: 2.8vw;
        font-weight: 700;
        font-family: var(--lato);
        color: var(--color-2);
        letter-spacing: var(--spacing-p);  
        margin-bottom: 2.5rem;
        @media screen and (max-width: 700px) {
            font-size: 1.4rem;
            margin-bottom: 0.7rem;
        }
    }

    p {
        font-size: 1.8vw;
        font-weight: 400;
        font-family: var(--lato);
        color: var(--color-2);
        letter-spacing: var(--spacing-p);
        @media screen and (max-width: 700px) {
            font-size: 1.2rem;
        } 
    }
    /* border-bottom: 6px dashed var(--line-color-1); */
    @media screen and (max-width: 1100px) {
        width: 100%;
        margin-bottom: 5px;
    }
    @media screen and (max-width: 700px) {
        border-left: 3px dashed var(--line-color-1);
        margin-bottom: 3px;
        padding-bottom: 0;
        padding-left: 2.5rem;
    }
    
`

const Text2 = styled(Text1)`
    border-left: 0;
    padding-top: 7rem;
    @media screen and (max-width: 1100px) {
        border-left: 6px dashed var(--line-color-1);
        order: 1;
        margin-bottom: 5px;
    }
    @media screen and (max-width: 700px) {
        border-left: 3px dashed var(--line-color-1);
        margin-bottom: 3px;
        padding-bottom: 0;
        padding-left: 2.5rem;
        padding-top: 3.5rem;
    }
`
const Text3 = styled(Text1)`
    padding-top: 7rem;
    @media screen and (max-width: 700px) {
        padding-top: 3.5rem;
    }
`
const Text4 = styled(Text2)``
const Text5 = styled(Text3)``
const Text6 = styled(Text2)``
const Text7 = styled(Text3)``
const Text8 = styled(Text2)`
    padding-bottom: 35rem;
    @media screen and (max-width: 1100px) {
       padding-bottom: 7rem;
    }
    @media screen and (max-width: 700px) {
        padding-bottom: 0;
    }
`

const Picture1 = styled.div`
    width: 50%;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        padding: 5rem;
        padding-right: 0;
        @media screen and (max-width: 700px) {
            padding: 2.5rem;
            padding-right: 0;
            padding-bottom: 0;
        }
    }
    @media screen and (max-width: 1100px) {
       width: 100%;
       border-left: 6px dashed var(--line-color-1);
       margin-bottom: 5px;
    }

    @media screen and (max-width: 700px) {
        border-left: 3px dashed var(--line-color-1);
        margin-bottom: 3px;
    }

`

const Picture2 = styled(Picture1)`
    border-top: 6px dashed var(--line-color-1);
    border-right: 6px dashed var(--line-color-1);
    border-bottom: 6px dashed var(--line-color-1);

    img {
        padding: 5rem;
        padding-left: 0;
        @media screen and (max-width: 1100px) {
            padding-left: 5rem;
            padding-right: 0;
        }
        @media screen and (max-width: 700px) {
            padding: 2.5rem;
            padding-right: 0;
            padding-bottom: 0;
        }
    }
    @media screen and (max-width: 1100px) {
       width: 100%;
       border: none;
       border-left: 6px dashed var(--line-color-1);
       order: 2;
       margin-bottom: 5px;
    }

    @media screen and (max-width: 700px) {
        border-left: 3px dashed var(--line-color-1);
        margin-bottom: 3px;
    }
`
const Picture3 = styled(Picture1)`
    padding-bottom: 0;
`
const Picture4 = styled(Picture2)``
const Picture5 = styled(Picture1)``
const Picture6 = styled(Picture2)``
const Picture7 = styled(Picture1)``
const Picture8 = styled(Picture2)`
    align-items: flex-start;
    border-bottom: 0;
    @media screen and (max-width: 1100px) {
       padding-bottom: 7rem;
    }
`

const OuterCircle = styled.div`
    width: 7rem;
    height: 7rem;
    background-color: var(--background-color-5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    left: -0.5rem;
    transform: translate(-50%);
    @media screen and (max-width: 700px) {
        width: 3.7rem;
        height: 3.7rem;
        left: -0.15rem;
    }
`
const InnerCircle = styled.div`
    width: 4.5rem;
    height: 4.5rem;
    background-color: var(--color-1);
    border: 1rem solid var(--background-color-1);
    border-radius: 50%;

    @media screen and (max-width: 700px) {
        width: 2.3rem;
        height: 2.3rem;
        border: 0.6rem solid var(--background-color-1);
    }
`
const OuterCircleBig = styled.div`
    width: 10rem;
    height: 10rem;
    background-color: var(--background-color-4);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    left: 49.8%;
    bottom: 0;
    transform: translate(-50%);
    @media screen and (max-width: 1100px) {
       left: 7rem;
       transform: translate(0);
    }
    @media screen and (max-width: 700px) {
        width: 4.6rem;
        height: 4.6rem;
        left: 2.8rem;
    }
`
const InnerCircleBig = styled.div`
    width: 7.5rem;
    height: 7.5rem;
    background-color: var(--background-color-5);
    border: 1.3rem solid var(--background-color-1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 100%;
    }
    @media screen and (max-width: 700px) {
        width: 3.4rem;
        height: 3.4rem;
        border: 0.7rem solid var(--background-color-1);
    }
`

const CircleComponent = () => {
    return (
        <OuterCircle>
            <InnerCircle></InnerCircle>
        </OuterCircle>
    )
}

const CircleComponentBig = () => {
    return (
        <OuterCircleBig>
            <InnerCircleBig>
                <img src={miniLogo} alt="company logo mini" />
            </InnerCircleBig>
        </OuterCircleBig>
    )
}



const StartUpPage = () => {

    const {t} = useTranslation()

    return (
        <StartUpPageContainer initial="hidden" animate="show" variants={pageAnimation}>
            <Helmet>
                <title>Start Up</title>
                <meta name="description" content="How we help small and medium enterprises" />
            </Helmet>
            <Navbar />
            <Main>
                <HeadContainer>
                    <Heading>
                        <h1>
                            {t("startup.title1")}&nbsp;{t("startup.title2")}
                            <br />
                            {t("startup.title3")}&nbsp;{t("startup.title4")}&nbsp;{t("startup.title5")}
                        </h1>
                    </Heading>
                    <IllustrationContainer>
                        <img src={thinkIllustraton} alt="" />
                    </IllustrationContainer>
                </HeadContainer>
                <StartUp1>
                    <Text1>
                        <CircleComponent />
                        <h1>01</h1>
                        <h2>{t("startup.head1")}</h2>
                        <p>{t("startup.comment1")}</p>
                    </Text1>
                    <Picture1>
                        <img src={startupIllustraton1} alt="Start Up Illustration 1" />
                    </Picture1>
                </StartUp1>
                <StartUp2>
                    <Picture2>
                        <img src={startupIllustraton2} alt="Start Up Illustration 2" />
                    </Picture2>
                    <Text2>
                        <CircleComponent />
                        <h1>02</h1>
                        <h2>{t("startup.head2")}</h2>
                        <p>{t("startup.comment2")}</p>
                    </Text2>
                </StartUp2>
                <StartUp3>
                    <Text3>
                    <CircleComponent />
                        <h1>03</h1>
                        <h2>{t("startup.head3")}</h2>
                        <p>{t("startup.comment3")}</p>
                    </Text3>
                    <Picture3>
                        <img src={startupIllustraton3} alt="Start Up Illustration 3" />
                    </Picture3>
                </StartUp3>
                <StartUp4>
                    <Picture4>
                        <img src={startupIllustraton4} alt="Start Up Illustration 4" />
                    </Picture4>
                    <Text4>
                        <CircleComponent />
                        <h1>04</h1>
                        <h2>{t("startup.head4")}</h2>
                        <p>{t("startup.comment4")}</p>
                    </Text4>
                </StartUp4>
                <StartUp5>
                    <Text5>
                    <CircleComponent />
                        <h1>05</h1>
                        <h2>{t("startup.head5")}</h2>
                        <p>{t("startup.comment5")}</p>
                    </Text5>
                    <Picture5>
                        <img src={startupIllustraton5} alt="Start Up Illustration 5" />
                    </Picture5>
                </StartUp5>
                <StartUp6>
                    <Picture6>
                        <img src={startupIllustraton6} alt="Start Up Illustration 6" />
                    </Picture6>
                    <Text6>
                        <CircleComponent />
                        <h1>06</h1>
                        <h2>{t("startup.head6")}</h2>
                        <p>{t("startup.comment6")}</p>
                    </Text6>
                </StartUp6>
                <StartUp7>
                    <Text7>
                    <CircleComponent />
                        <h1>07</h1>
                        <h2>{t("startup.head7")}</h2>
                        <p>{t("startup.comment7")}</p>
                    </Text7>
                    <Picture7>
                        <img src={startupIllustraton7} alt="Start Up Illustration 7" />
                    </Picture7>
                </StartUp7>
                <StartUp8>
                    <Picture8>
                        <img src={startupIllustraton8} alt="Start Up Illustration 8" />
                    </Picture8>
                    <Text8>
                        <CircleComponent />
                        <h1>08</h1>
                        <h2>{t("startup.head8")}</h2>
                        <p>{t("startup.comment8")}</p>
                    </Text8>
                    <CircleComponentBig />
                </StartUp8>
            </Main>
            <Footer />
        </StartUpPageContainer>
    )
}

export default StartUpPage