import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import emailImage from "../images/email.svg";
import spinnerIcon from "../images/spinner.svg";
import { keyframes } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

const ContactContainer = styled.div`
  background-color: var(--background-color-contact);
  /* background-color: yellow; */
  width: 100%;
  border-radius: 1.5rem;
  display: flex;
  flex-flow: row nowrap;
  letter-spacing: var(--spacing-p);
  position: relative;
`;

const PictureContainer = styled.div`
  width: 40%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  h1 {
    color: var(--color-2);
    letter-spacing: var(--spacing-h);
    font-weight: 700;
    /* font-size: var(--size-h3); */
    font-size: 3rem;
  }
  img {
    width: 100%;
    @media screen and (max-width: 900px) {
      opacity: 0.1;
      width: 170%;
    }
  }
  @media screen and (max-width: 900px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    z-index: -1;
  }
`;
const FormContainer = styled.div`
  width: 60%;
  padding: 8rem;
  border-left: 1px solid var(--line-color-3);
  @media screen and (max-width: 900px) {
    width: 100%;
    border-left: none;
  }
  @media screen and (max-width: 900px) {
    padding: 4rem;
  }
`;
const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;
const Label = styled.label`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 3rem;
  color: var(--color-2);
  font-size: 2.2rem;
  @media screen and (max-width: 1100px) {
    font-size: 1.8rem;
    margin-bottom: 3rem;
  }
`;

const Input = styled.input`
  margin-top: 1.5rem;
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 1px solid var(--line-color-2);
  letter-spacing: var(--spacing-p);
  font-size: 1.9rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  transition: all 0.5s linear;
  color: var(--color-3);
  &:focus {
    border-bottom: 1px solid var(--line-color-1);
  }
  @media screen and (max-width: 1100px) {
    margin-top: 1.2rem;
  }
`;
const Textarea = styled(Input)`
  &:focus {
    border-bottom: 1px solid var(--line-color-1);
  }
`;
const Button = styled.button`
  align-self: center;
  padding: 1.5rem 7rem;
  background-color: var(--background-color-4);
  box-shadow: 0 0 1.5rem rgba(233, 79, 55, 0.66);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5rem;
  margin-top: 3rem;
  letter-spacing: var(--spacing-p);
  color: var(--color-1);
  font-size: 2rem;
  @media screen and (max-width: 1100px) {
    font-size: 1.8rem;
    padding: 1.5rem 5rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 8rem;

  @media screen and (max-width: 900px) {
    margin-bottom: 4rem;
  }
`;
const Title = styled.h1`
  font-size: 4.5rem;
  font-weight: 700;
  color: var(--color-2);
  margin-right: 2rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 900px) {
    font-size: 3rem;
  }
`;

const Line = styled.div`
  width: 18rem;
  height: 0.8rem;
  background-color: var(--background-color-4);
  @media screen and (max-width: 900px) {
    height: 0.6rem;
    width: 13rem;
    margin: 0 0 1.5rem;
  }
`;

const Err = styled.div`
  color: red;
  font-size: 1.4rem;
`;

const spinnerAnimation = keyframes`
    to {
        transform: rotate(360deg);
    }
`;

const SpinnerIcon = styled.img`
  width: 2rem;
  animation-name: ${spinnerAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

const Popup = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
`;
const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: rgb(0 0 0 / 25%); */
`;
const Message = styled.div`
  background-color: #fff;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.8rem;
  letter-spacing: 1px;
  padding: 3rem 2rem;
  border-radius: 2rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  @media screen and (max-width: 700px) {
    font-size: 1.2rem;
    padding: 1rem 1rem;
  }
`;

const popupVariant = {
  hidden: {
    opacity: 0,
    y: 200,
    transition: {
      duration: 1,
    },
    // y: 300,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
    y: 200,
    transition: {
      duration: 1,
    },
  },
};

const Spinner = () => {
  return (
    <>
      <SpinnerIcon src={spinnerIcon} alt="spinner" />
    </>
  );
};

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState({});
  const [status, setStatus] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    const timer = setTimeout(() => {
      setStatus(false);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [status]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!name) {
      setError({ ...error, name: "Name is required!" });
      return;
    } else {
      setError({ ...error, name: null });
    }

    if (!email || !emailRegex.test(email)) {
      setError({ ...error, email: "Valid email is required!" });
      return;
    } else {
      setError({ ...error, email: null });
    }

    if (!subject) {
      setError({ ...error, subject: "Subject is required!" });
      return;
    } else {
      setError({ ...error, subject: null });
    }

    if (!message) {
      setError({ ...error, message: "Message is required!" });
      return;
    } else {
      setError({ ...error, message: null });
    }

    const params = JSON.stringify({
      name: name,
      subject: subject,
      email: email,
      desc: message,
    });

    // let nameArray = name.split(" ")[0];
    // let firstName = nameArray[0];
    // let lastName = nameArray.length > 1 ? nameArray[1] : nameArray[0];

    // const newParams = JSON.stringify({
    //   oid: "00D5g000000KcTW",
    //   retURL: "https://acumencog.com/contact",
    //   first_name: firstName,
    //   last_name: lastName,
    //   email: email,
    //   description: "Subject: " + subject + " Message:" + message,
    //   Submit: "Submit",
    // });

    setLoading(true);

    await axios
      .post(
        "https://5sewyrpk10.execute-api.ap-south-1.amazonaws.com/default/",
        // "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8",
        // "https://acumencog-dev-ed.lightning.force.com/servlet/servlet.WebToLead?encoding=UTF-8",
        params,
        {
          headers: {
            "Access-Control-Allow-Origin": "https://acumencog.com",
            "content-type": "application/json",
          },
        }
      )
      .then(function (response) {
        reset();
        setLoading(false);
      })
      .catch(function (error) {
        setStatus({
          isSuccess: false,
          msg: "An error has occured, please try again.",
        });
        reset();
        setLoading(false);
      });
  };

  const setValidEmail = (value) => {
    if (value && !emailRegex.test(value)) {
      setError({ ...error, email: "Valid email is required!" });
    } else {
      setError({ ...error, email: null });
    }
    setEmail(value);
  };

  const reset = () => {
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
    setError({
      name: null,
      email: null,
      subject: null,
      message: null,
    });
    setStatus({
      isSuccess: true,
      msg: "We have received your message. We will get back to you shortly!",
    });
  };

  return (
    <>
      <TitleContainer>
        <Title>Connect With Us</Title>
        <Line />
      </TitleContainer>
      <ContactContainer>
        <AnimatePresence>
          {status && (
            <Popup
              initial="hidden"
              animate="show"
              exit="exit"
              variants={popupVariant}
            >
              <Overlay />
              <Message>
                We have received your message.
                <br />
                We will get back to you shortly!
              </Message>
            </Popup>
          )}
        </AnimatePresence>
        <PictureContainer>
          <img src={emailImage} alt="@ icon" />
        </PictureContainer>
        <FormContainer>
          <Form onSubmit={submitHandler}>
            <Label>
              Name *
              <Input
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setError({ ...error, name: null });
                }}
                errorMessage={error && error["name"]}
              />
              {error && error["name"] && <Err>{error["name"]}</Err>}
            </Label>
            <Label>
              Email *
              <Input
                type="text"
                value={email}
                onChange={(e) => {
                  setValidEmail(e.target.value);
                  setError({ ...error, email: null });
                }}
                errorMessage={error && error["email"]}
              />
              {error && error["email"] && <Err>{error["email"]}</Err>}
            </Label>
            <Label>
              Subject *
              <Input
                type="text"
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                  setError({ ...error, subject: null });
                }}
                errorMessage={error && error["subject"]}
              />
              {error && error["subject"] && <Err>{error["subject"]}</Err>}
            </Label>
            <Label>
              How can we help you? *
              <Textarea
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  setError({ ...error, message: null });
                }}
                errorMessage={error && error["message"]}
              ></Textarea>
              {error && error["message"] && <Err>{error["message"]}</Err>}
            </Label>
            <Button disabled={isLoading}>
              {isLoading ? <Spinner /> : "Send"}
            </Button>
          </Form>
        </FormContainer>
      </ContactContainer>
    </>
  );
};

export default Contact;
